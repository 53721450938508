/* eslint-disable max-lines */
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.black};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const Content = styled.div`
display: flex;
flex-direction: column;
width: 100%;
max-width: 65vw;
justify-content: space-evenly;
align-items: center;

`;

const TitleImage = styled(GatsbyImage)`
  height: 100%;
  width: 80px;
  border-radius: 100%;
  margin-right: 25px;
  position: relative;
  align-self: center;

  ${breakpoints.lg`
    margin-right: 50px;
    width: 120px;
  `}
`;

const MaskShadow = styled.div`
  background-size: 100%;
  filter: drop-shadow(1px 1px 4px rgba(166, 50, 69, 0.7));
  -webkit-filter: drop-shadow(1px 1px 4px rgba(166, 50, 69, 0.7));
  -moz-filter: drop-shadow(1px 1px 4px rgba(166, 50, 69, 0.7));
  -ms-filter: drop-shadow(1px 1px 4px rgba(166, 50, 69, 0.7));
  -o-filter: drop-shadow(1px 1px 4px rgba(166, 50, 69, 0.7));
`;

const Headline = styled.div`
  font-family: ${({ theme }) => theme.typography.mosk.bold};
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 1.5px;
  text-align: center;

  ${breakpoints.xs`
    font-size: 24px;
  `}
  ${breakpoints.sm`
    font-size: 32px;
    line-height: 43px;
  `}
`;
const Subtitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 6vh;

  ${breakpoints.xs`
    font-size: 12px;
  `}
  ${breakpoints.lg`
    font-size: 32px;
  `}
`;

const BigLabel = styled.div`
  font-family: ${({ theme }) => theme.typography.mosk.bold};
  font-size: 24px;
  font-weight: 800;
  line-height: 43px;
  letter-spacing: 1.5px;
  text-align: center;

  ${breakpoints.xs`
    font-size: 24px;
  `}
  ${breakpoints.sm`
    font-size: 32px;
  `}
`;

const SmallLabel = styled.div`
  font-family: ${({ theme }) => theme.typography.mosk.medium};
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 1.5px;
  text-align: left;

  ${breakpoints.xs`
    font-size: 16px;
  `}
  ${breakpoints.sm`
    font-size: 24px;
  `}
`;

const FounderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 50px 0 50px 0;
  ${breakpoints.lg`
    margin: 100px 0 100px 0;
  `}
`;

const FounderInformation = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-around;
height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export {
  Container,
  Content,
  ButtonContainer,
  TitleImage,
  MaskShadow,
  Headline,
  Subtitle,
  BigLabel,
  SmallLabel,
  FounderContainer,
  FounderInformation,
};
