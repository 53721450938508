import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import { SmallHeadline } from '../../components/headlines/index';

const Container = styled.div`
  ${breakpoints.xs`
    padding: 90px 0 15vh;
    `}
  ${breakpoints.md`
      padding: 90px 0 15vh;
    `}
  ${breakpoints.lg`
      padding: 90px 0 15vh;
    `}
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  place-items: center;
`;

const OurValuesHeadline = styled(SmallHeadline)`
  ${breakpoints.xs`
    margin-bottom: 120px;
  `}
  ${breakpoints.md`
    margin-bottom: 150px;
  `}
  ${breakpoints.lg`
    margin-top: 40px;
  `}
`;

const ValuesContainer = styled.div`
  ${breakpoints.xs`
    width: 300px;
  `}
  ${breakpoints.sm`
    max-width: 619px;
  `}
  ${breakpoints.md`
    width: auto;
  `}
  ${breakpoints.lg`
    max-width: 1400px;
  `}
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  place-items: baseline;
  justify-content: center;
  row-gap: 5px;
  column-gap: 45px;
`;

export {
  Container,
  OurValuesHeadline,
  ValuesContainer,
};
