import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { animated } from 'react-spring';

import breakpoints from '../../styles/breakpoints';
import { HeroHeadline } from '../../components/headlines/index';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Headline = styled(HeroHeadline)`
  && {
    height: unset;
    width: unset;
    margin-top: unset;
  }
  ${breakpoints.xs`
     margin-bottom: 15px;
  `}
  ${breakpoints.lg`
     margin-bottom: 25px;
  `}

`;

const Saturn = styled(animated(GatsbyImage))`
  ${breakpoints.xs`
    width: 50px;
  `}
  ${breakpoints.md`
    width: 200px;
  `}

  /* stylelint-disable declaration-no-important */
  position: absolute !important;
  right: 20vw;
  bottom: 15vh;
`;

const SpaceStaionDiv = styled(animated.div)`
  ${breakpoints.xs`
    width: 90px;
  `}
  ${breakpoints.md`
    width: 120px;
  `}
  position: absolute !important;
  right: 10vw;
  top: 20vh;
`;

const Pluto = styled(animated(GatsbyImage))`
  ${breakpoints.xs`
    width: 70px;
  `}
  ${breakpoints.md`
    width: 150px;
  `}
  position: absolute !important;
  left: 20vw;
  bottom: 20vh;
`;

export {
  Container,
  Headline,
  Saturn,
  SpaceStaionDiv,
  Pluto,
};
