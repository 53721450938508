import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion, MotionStyle } from 'framer-motion';

import breakpoints from '../../styles/breakpoints';
import { SmallHeadline, PitchHeadline } from '../../components/headlines/index';

type MoonProps = {
  src?: string,
  style: MotionStyle,
};

const Container = styled.div`
  display: grid;
  height: 100vh;
  place-items: start center;

  ${breakpoints.xs`
    grid-template-rows: 150px 1fr;
  `}
  ${breakpoints.md`
    grid-template-rows: 300px 1fr;
  `}
  ${breakpoints.lg`
    grid-template-rows: 250px 1fr;
  `}
  ${breakpoints.xl`
    grid-template-rows: 160px 1fr;
  `}
  z-index: 1;
`;

const OurMissionHeadline = styled(SmallHeadline)`
  ${breakpoints.xs`
    margin-top: 40px;
  `}
  ${breakpoints.md`
    margin-top: -40px;
  `}
  ${breakpoints.lg`
    margin-top: 40px;
  `}
`;

const MissionSlogan = styled(PitchHeadline)`
  ${breakpoints.xs`
    font-size: 27px;
    margin-top: -75px;
    margin-left: -160px;
  `}
  ${breakpoints.md`
    width: 500px;
    heigth: 200px;
    margin-top: -100px;
    margin-left: -250px;
    font-size: 50px;
  `}
  ${breakpoints.lg`
    width: 940px;
    height: 200px;
    font-size: 70px;
    margin-top: -100px;
    margin-left: -470px;
  `}
  ${breakpoints.xl`
    width: 940px;
    height: 200px;
    font-size: 75px;
  `}
  text-align: center;
  z-index: 1;

  /* stylelint-disable declaration-no-important */
  position: absolute !important;
  top: 50%;
  left: 50%;
`;

const Earth = styled(GatsbyImage)`
  ${breakpoints.xs`
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
  `}
  ${breakpoints.md`
    width: 200px;
    height: 200px;
    margin-top: -100px;
    margin-left: -100px;
  `}
  ${breakpoints.lg`
    width: 300px;
    height: 300px;
    margin-top: -150px;
    margin-left: -150px;
  `}
  ${breakpoints.xl`
    width: 344px;
    height: 344px;
    margin-top: -167px;
    margin-left: -167px;
  `}
  opacity: 0.6;
  position: absolute !important;
  top: 50%;
  left: 50%;
  z-index: 0;
`;

const Orbit = styled(GatsbyImage)`
  ${breakpoints.xs`
    width: 360px;
    height: 132px;
    margin-top: -65px;
    margin-left: -180px;
  `}
  ${breakpoints.md`
    width: 650px;
    height: 250px;
    margin-top: -110px;
    margin-left: -325px;
  `}
  ${breakpoints.lg`
    width: 950px;
    height: 340px;
    margin-top: -130px;
    margin-left: -475px;
  `}
  ${breakpoints.xl`
    width: 1200px;
    height: 400px;
    margin-top: -160px;
    margin-left: -600px;
  `}
  opacity: 1;
  position: absolute !important;
  top: 50%;
  left: 50%;
  z-index: 0;

  img {
    object-fit: contain !important;
  }
`;

const MoonDiv = styled(motion.div)<MoonProps>`
  ${breakpoints.xs`
    width: 70px;
    height: 70px;
    margin-top: -34px;
    margin-left: 113px;
  `}
  ${breakpoints.md`
    width: 150px;
    height: 150px;
    margin-top: -15px;
    margin-left: 115px;
  `}
  ${breakpoints.lg`
    width: 200px;
    height: 200px;
    margin-top: 20px;
    margin-left: 125px;
  `}
  ${breakpoints.xl`
    width: 220px;
    height: 220px;
    margin-top: 20px;
    margin-left: 165px;
  `}
  opacity: 1;
  position: absolute !important;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: hidden;
`;

/* stylelint-enable declaration-no-important */

export {
  Container,
  OurMissionHeadline,
  MissionSlogan,
  Earth,
  Orbit,
  MoonDiv,
};
