import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  display: grid;
  grid-row-gap: 75px;
  padding: 10vh 0;
  justify-items: center;

  ${breakpoints.xs`
    grid-template-rows: 100px 1fr;
    grid-row-gap: 25px;
  `}
  ${breakpoints.lg`
    grid-template-rows: 200px 1fr;
    grid-row-gap: 75px;
    padding: 10vh 0;
  `}
  ${breakpoints.xl`
    padding: 10vh 10vw 10vh;
  `}
`;

const ContentContainer = styled.div`
  ${breakpoints.xs`
    margin-bottom: 100px;
  `}
  ${breakpoints.md`
    margin-bottom: unset;
  `}
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: space-evenly;
`;

export {
  Container,
  ContentContainer,
};

