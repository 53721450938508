type EnvVariables = {
  GATSBY_SEND_MAIL_URL: string | undefined,
  GATSBY_SOLUTIONS_MAIL: string | undefined,
};

function getEnvVariables(): EnvVariables {
  return {
    GATSBY_SEND_MAIL_URL: process.env.GATSBY_SEND_MAIL_URL,
    GATSBY_SOLUTIONS_MAIL: process.env.GATSBY_SOLUTIONS_MAIL,
  };
}

export {
  getEnvVariables,
};
