import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';

type AnimatedGatsbyImageProps = {
  image: IGatsbyImageData,
  alt: string,
};

const AnimatedGatsbyImage = styled(GatsbyImage) <AnimatedGatsbyImageProps>`
  position: unset !important;
`;

export default AnimatedGatsbyImage;
