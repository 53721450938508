import React from 'react';
import { Tooltip, TooltipText } from '../tooltip/tooltip';
import { Logo } from './components';

type ValueProps = {
  title: string,
  src: string,
  description: string,
};

const Technology: React.FC<ValueProps> = ({ title, src, description }) =>
  <Tooltip
    allowHTML
    content={
      <TooltipText dangerouslySetInnerHTML={{ __html: description }} />
    }
    interactive
  >
    <Logo
      src={src}
      alt={title}
      loading='lazy'
    />
  </Tooltip>;

export default Technology;
