/* eslint-disable @typescript-eslint/no-extra-parens */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useViewportScroll, useTransform } from 'framer-motion';

import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { BackgroundSilver, BackgroundDarken } from '../../components/components';
import {
  OurMissionHeadline,
  MissionSlogan,
  Container,
  Earth,
  MoonDiv,
  Orbit,
} from './components';

type QueryData = {
  earthPicture: IGatsbyImageData,
  moonPicture: IGatsbyImageData,
  orbitPicture: IGatsbyImageData,
  allContentfulSection: {
    totalCount: number,
    nodes: {
      title: string,
      subtitle: string,
    }[],
  },
};

type MissionSectionProps = {
  previousContainerHeight: number,
};

const MissionSection: React.FC<MissionSectionProps> = ({ previousContainerHeight }) => {
  const { scrollY } = useViewportScroll();

  const viewPortHeight = typeof window === 'undefined' ? 0 : window.innerHeight;
  const startAnimation = previousContainerHeight - (viewPortHeight * 0.25);
  const endAnimation = previousContainerHeight + (viewPortHeight * 0.75);

  const ymoon = useTransform(scrollY, [startAnimation, endAnimation], [0, 65]);
  const xmoon = useTransform(scrollY, [startAnimation, endAnimation], [0, -180]);
  const moonscale = useTransform(scrollY, [startAnimation, endAnimation], [0.9, 1.1]);

  const { earthPicture, moonPicture, orbitPicture, allContentfulSection }: QueryData
  = useStaticQuery(
    graphql`
      query {
        earthPicture: file(relativePath: { eq: "earth-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 500,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        moonPicture: file(relativePath: { eq: "moon-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 500,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        orbitPicture: file(relativePath: { eq: "orbit-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 500,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        allContentfulSection(filter: {title: {eq: "UNSERE MISSION"}}) {
          totalCount
          nodes {
            title
            subtitle
          }
        }
      }
    `,
  );

  const { nodes: [{ title, subtitle }] } = allContentfulSection;

  return (
    <BackgroundSilver>
      <BackgroundDarken>
        <Container id='mission'>
          <OurMissionHeadline>{title}</OurMissionHeadline>
          <div>
            <MissionSlogan>
              {subtitle}
            </MissionSlogan>
            <Earth image={getImage(earthPicture)!} alt='earth' />
            <Orbit image={getImage(orbitPicture)!} alt='orbit' />
            <MoonDiv
              style={{ y: ymoon, x: xmoon, scale: moonscale }}
            >
              <GatsbyImage image={getImage(moonPicture)!} alt='moon' />
            </MoonDiv>
          </div>
        </Container>
      </BackgroundDarken>
    </BackgroundSilver>
  );
};

export default MissionSection;
