import styled from 'styled-components';

import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  display: grid;
  position: relative;
  bottom: 0;
  left: 0;
  min-height: 100vh;
  place-items: start center;

  ${breakpoints.xs`
    grid-template-rows: 150px 1fr;
  `}
  ${breakpoints.md`
    grid-template-rows: 350px 1fr;
  `}
`;
const TechnologiesGrid = styled.div`
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  place-items: center;
  justify-content: space-evenly;
  align-content: space-around;
  margin-bottom: 70px;
  ${breakpoints.xs`
    width: 300px;
    margin-bottom: 50px;
  `}
  ${breakpoints.md`
    width: 500px;
    margin-bottom: 70px;
  `}
  ${breakpoints.lg`
    width: 900px;
  `}
`;
export {
  Container,
  TechnologiesGrid,
};
