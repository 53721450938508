import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

type LogoProps = {
  src: string,
  alt: string,
  loading: string,
};

const Logo = styled.img<LogoProps>`
  ${breakpoints.xs`
    width: 80px;
    height: 65px;
    margin: 20px 10px;
  `}
  ${breakpoints.md`
    width: 120px;
    height: 100px;
  `}
  ${breakpoints.lg`
    width: 320px;
    height: 100px;
  `}
  ${breakpoints.xl`
    width: 150px;
    height: 100px;
  `}
`;

const Tooltip = styled.div`
  min-height: 200px;
  min-width: 200px;
  background-color: whitesmoke;
`;

export {
  Logo,
  Tooltip,
};
