import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import {
  BackgroundSilver,
} from '../components';
import {
  SmallHeadline,
  SectionHeadline,
} from '../headlines/index';
import {
  Container,
  GridContainer,
  ContentBox,
  Icon,
  HeadlineContainer,
  ContentContainer,
  TextContainer,
  Quote,
  ImageContainer,
  TitleImage,
  Quotee,
  Position,
  SecondContainer,
} from './component';

type QueryData = {
  allContentfulSection: {
    nodes: {
      title: string,
      subtitle: string,
      content: {
        name: string,
        image: {
          gatsbyImageData: IGatsbyImageData,
        },
      }[],
    }[],
    title: string,
  },
  allContentfulSectionQuote: {
    nodes: [
      {
        subtitle: string,
        title: string,
        image: [
          fluid: {
            title: string,
            description: string,
            gatsbyImageData: IGatsbyImageData,
          }
        ],
      }
    ],
  },
};

const DevTeamsPlatformQuote: React.FC = () => {
  const { allContentfulSection, allContentfulSectionQuote }:
  QueryData = useStaticQuery(
    graphql`
      query {
        allContentfulSection(filter: {title: {eq: "Geräte für die wir Entwickeln"}}) {
          nodes {
            title
            subtitle
            content {
              ... on ContentfulTargetDevices {
                name
                image {
                  gatsbyImageData (
                    width: 750
                    height: 750
                    formats: WEBP
                    quality: 50
                    placeholder: TRACED_SVG
                  )
                }
              }
            }
          }
        }
        allContentfulSectionQuote: allContentfulSection(filter: { title: { eq: "DAS SAGEN UNSERE KUNDEN" } }) {
          nodes {
            subtitle
            title
            image {
              title
              description
              gatsbyImageData
            }
          }
        }
      }
    `,
  );

  const {
    nodes: [
      {
        subtitle,
        content,
      },
    ],
  } = allContentfulSection;

  const {
    nodes: [
      {
        title: quoteTitle,
        subtitle: quote,
        image: quotee,
      },
    ],
  } = allContentfulSectionQuote;

  return (
    <BackgroundSilver>
      <Container>
        <HeadlineContainer>
          <SmallHeadline>{subtitle}</SmallHeadline>
        </HeadlineContainer>
        <GridContainer>
          {content.map((data) => (
            <ContentBox key={data.name} >
              <Icon image={data.image.gatsbyImageData} alt={`image for ${data.name}`} />
              <SectionHeadline dark>
                {data.name}
              </SectionHeadline>
            </ContentBox>
          ))}
        </GridContainer>
      </Container>
      <SecondContainer>
        <HeadlineContainer>
          <SmallHeadline>{quoteTitle}</SmallHeadline>
        </HeadlineContainer>
        <ContentContainer>
          <TextContainer>
            <Quote>{quote}</Quote>
            <Quotee>{quotee[0].title}</Quotee>
            <Position>{quotee[0].description}</Position>
          </TextContainer>
          <ImageContainer>
            <TitleImage image={getImage(quotee[0].gatsbyImageData)!} alt={`image of ${quotee[0].title}`} />
          </ImageContainer>
        </ContentContainer>
      </SecondContainer>
    </BackgroundSilver>
  );
};

export default DevTeamsPlatformQuote;
