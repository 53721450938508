/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/promise-function-async */
import React from 'react';
import { navigate } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ButtonOutlined } from '../buttons/index';
import { BlogCardContainer, BlogCardContent, BlogPostImage, SubtitleText, BlogCardTitleText, BlogCardExcerpt, ContentWrapper, TextWrapper } from './components';

type BlogCardProps = {
  title: string,
  subtitle: string,
  excerpt: string,
  isOdd: boolean,
  image: IGatsbyImageData,
  link: string,
};

const BlogCard: React.FC<BlogCardProps> = ({ title, subtitle, excerpt, isOdd, image, link }) =>
  <BlogCardContainer >
    <BlogPostImage image={image} alt={`Image of Blogpost ${title}`} />
    <BlogCardContent>
      <ContentWrapper isOdd={isOdd}>
        <TextWrapper>
          <SubtitleText>{subtitle}</SubtitleText>
          <BlogCardTitleText>{title}</BlogCardTitleText>
          <BlogCardExcerpt>{excerpt}</BlogCardExcerpt>
        </TextWrapper>
        <ButtonOutlined
          type='button'
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          primary
          onClick={async () => navigate(`/blog/${link}`)}
        >
          Zum Artikel
        </ButtonOutlined>
      </ContentWrapper>
    </BlogCardContent>
  </BlogCardContainer>;

export default BlogCard;
