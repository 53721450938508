import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// import usePlausible from '../../hooks/use-plausible';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { BackgroundDarken, BackgroundSilver } from '../components';
import { BigLabel, ButtonContainer, Container, Content, FounderContainer, FounderInformation, Headline, MaskShadow, SmallLabel, TitleImage } from './components';
import ContactButton from './buttons';

type ButtonInformation = {
  title: string,
  link: string,
};

type QueryData = {
  'allContentfulSection': {
    'nodes': [
      {
        'buttons': ButtonInformation[],
        'subtitle': string,
        content: [
          {
            id: string,
            name: string,
            picture: {
              gatsbyImageData: IGatsbyImageData,
            },
          }
        ],
      }
    ],
  },
};

const DevTeamsIntroduction: React.FC = () => {
  // const { plausible } = usePlausible();
  const { allContentfulSection }: QueryData = useStaticQuery(graphql`
    query {
      allContentfulSection(filter: {contentful_id: {eq: "5gopP5zDwVWTKEdlCmK9Im"}}) {
        nodes {
          buttons {
            title
            link
          }
          subtitle
          content {
            ... on ContentfulEmployee {
              id
              name
              picture {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const {
    nodes:
    [{ subtitle, buttons, content }],
  } = allContentfulSection;

  return (
    <>
      <BackgroundDarken>
        <BackgroundSilver>
          <Container >
            <Content>
              <Headline>{subtitle}</Headline>
              <FounderContainer>
                <MaskShadow>
                  <TitleImage image={getImage(content[0].picture.gatsbyImageData)!} alt='image of ' />
                </MaskShadow>
                <FounderInformation>
                  <BigLabel>{content[0].name}</BigLabel>
                  <SmallLabel>Co-Founder</SmallLabel>
                </FounderInformation>
              </FounderContainer>
              <ButtonContainer>
                {
                  buttons.map((el) =>
                    <ContactButton
                      width='100%'
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      key={el.title}
                      primary
                      onClick={() => {
                        window.location.href = `tel:${el.link}`;
                      }}
                    >
                      {el.title}

                    </ContactButton>,
                  )
                }
              </ButtonContainer>
            </Content>
          </Container>
        </BackgroundSilver>
      </BackgroundDarken>
    </>
  );
};

export default DevTeamsIntroduction;
