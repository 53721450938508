import React from 'react';
import styled from 'styled-components';

const Checkbox = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: 40px;
  cursor: pointer;
  font-size: 14px;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  user-select: none;
  line-height: normal;

  /* Add grey background on mouse-over */
  &:hover input ~ .checkmark {
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.color.hamam.tint};
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.color.hamam.tint}
  }

  /* Show the checkmark when checked */
  & input:checked ~ .checkmark:after {
    display: block;
  }
`;

/* Hide the default checkbox */
const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  background-color: transparent;

  /* Add blue background when checked */
  :checked ~ .checkmark {
    border: 2px solid ${({ theme }) => theme.color.hamam.alternative};
  }

  :focus ~ .checkmark {
    border: 2px solid ${({ theme }) => theme.color.hamam.tint};
  }
`;

/* Create the custom checkbox */
const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.color.basic.black};
  border-radius: 5px;

  /* Create the checkmark/indicator */
  ::after {
    content: "";
    position: absolute;
    display: none;
    left: 3px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    border-color: ${({ theme }) => theme.color.hamam.alternative};
  }
`;
type CustomCheckboxProps = {
  info: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
};

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  info,
  onChange,
}) => {
  return (
    <Checkbox className='container'>
      {info}
      <CheckboxInput onChange={(e) => onChange(e)} type='checkbox' />
      <Checkmark className='checkmark' />
    </Checkbox>
  );
};

export default CustomCheckbox;
