import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  padding: 0 0 175px;

  > h1 {
    margin-top: 90px;
  }

  > span {
    margin-bottom: 40px;
  }

  > * + * {
    margin-top: 15px;
  }
`;

export default Container;
