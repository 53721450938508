import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { withArtDirection, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import {
  BackgroundSilver, BackgroundDarken,
} from '../../components/components';
import { SmallHeadline } from '../../components/headlines/index';
import { Container, ProcessContainer, SubLineContainer, TextBox, StepHeadline, StepText, ProcessImage } from './components';

type QueryData = {
  processMobile: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData,
    },
  },
  process: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData,
    },
  },
  allContentfulSection: {
    nodes: {
      title: string,
      subtitle: string,
      content: {
        title: string,
        subtitle: {
          subtitle: string,
        },
      }[],
    }[],
  },
};

const ProcessSection: React.FC = () => {
  const {
    process,
    processMobile,
    allContentfulSection,
  }: QueryData = useStaticQuery(
    graphql`
      query {
        processMobile: file(relativePath: { eq: "process-background-mobile.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 490,
              layout: CONSTRAINED,
              quality: 100,
            )
          }
        }
        process: file(relativePath: { eq: "process-background-desktop.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 4160,
              layout: CONSTRAINED,
              quality: 100,
            )
          }
        }
        allContentfulSection(filter: {title: {eq: "Prozess"}}) {
          nodes {
            title
            subtitle
            content {
              ... on ContentfulProcess {
                title
                subtitle {
                  subtitle
                }
              }
            }
          }
        }
      }
    `);

  const { nodes: [{ title, content }] } = allContentfulSection;

  const processSources = withArtDirection(getImage(process.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(processMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);

  return (
    <BackgroundSilver>
      <BackgroundDarken>
        <ProcessImage image={getImage(processSources)!} alt='planets' />
        <Container id='process'>
          <SmallHeadline>{title}</SmallHeadline>
          <ProcessContainer>
            <TextBox>
              <StepHeadline>{content[0].title}</StepHeadline>
              <StepText>
                {content[0].subtitle.subtitle}
              </StepText>
            </TextBox>
            <TextBox>
              <StepHeadline>{content[1].title}</StepHeadline>
              <StepText>
                {content[1].subtitle.subtitle}
              </StepText>
            </TextBox>
            <TextBox>
              <StepHeadline>{content[2].title}</StepHeadline>
              <StepText>
                {content[2].subtitle.subtitle}
              </StepText>
            </TextBox>
            <SubLineContainer>
              <TextBox>
                <StepHeadline>{content[3].title}</StepHeadline>
                <StepText>
                  {content[3].subtitle.subtitle}
                </StepText>
              </TextBox>
              <TextBox>
                <StepHeadline>{content[4].title}</StepHeadline>
                <StepText>
                  {content[4].subtitle.subtitle}
                </StepText>
              </TextBox>

            </SubLineContainer>
          </ProcessContainer>
          {/* <Button
              type='button'
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              primary
            >
              Methodik ansehen
            </Button> */}
        </Container>
      </BackgroundDarken>
    </BackgroundSilver>
  );
};

export default ProcessSection;
