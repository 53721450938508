/* eslint-disable import/prefer-default-export */
/* eslint-disable max-params */

import { getEnvVariables } from './get-env-variables';

/**
 * Sends an email from the no-reply account
 * @param {string} subject The Email's subject
 * @param {string} body The Email's content
 * @param {string} mailFrom The Sender's mail address
 * @param {string} senderName The Sender's name
 * @param {string} mailTo The mail-address of the recipient
 * @throws {Error} if mail could not be sent
 */
async function sendMail(
  subject: string,
  body: string,
  mailFrom: string,
  senderName: string,
  mailTo: string,
): Promise<void> {
  const envVariables = getEnvVariables();
  if (!envVariables.GATSBY_SEND_MAIL_URL) {
    console.error(`Could not send email due to missing Cloud Function URL`);
    throw Error(`Could not send email due to missing Cloud Function URL`);
  }
  const request = new Request(`${envVariables.GATSBY_SEND_MAIL_URL}?subject=${subject}&body=${JSON.stringify(body)}&name=${senderName}&mailTo=${mailTo}&mailFrom=${mailFrom}`);
  const response = await fetch(request);

  if (!response.ok) {
    throw Error(`Could not send email due to: ${response.status} ${response.statusText}`);
  }
}

function fillContactTemplate(
  name: string,
  email: string,
  message: string,
  phoneNumber?: string,
  company?: string): string {
  return `
    <p style="white-space: pre-line; font-size: 15px;">Hey Solutions-Team 🚀\nhier ist eine neue Anfrage von <strong>${name}</strong>${company ? ` aus dem Unternehmen <strong>${company}</strong>` : ''}!\nEure nächste Mission lautet:\n</p>
    <p style="white-space: pre-line; font-size: 15px;">${message}</p>
    <p style="white-space: pre-line; font-size: 15px;">\nKontaktiert die Person doch gleich mit einer E-Mail an <strong>${email}</strong>${phoneNumber ? ` oder per Telefon unter <strong><a href="tel:${phoneNumber}">${phoneNumber}</a></strong> an` : ''}!</p>
    `;
}

export {
  sendMail,
  fillContactTemplate,
};
