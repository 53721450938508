import React, { RefObject } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { BackgroundButterscotch } from '../../components/components';
import { SmallHeadline, PageHeadline } from '../../components/headlines/index';
import TeamMember from '../../components/team-member/team-member';
import PositionMainRelative from '../../styles/position-main-relative';
import { Container, HeadlineContainer, TeamMemberContainer } from './components';

type QueryData = {
  allContentfulSection: {
    nodes: {
      title: string,
      subtitle: string,
      content: {
        briefDescription: {
          briefDescription: string,
        },
        name: string,
        picture: {
          gatsbyImageData: IGatsbyImageData,
        },
        skills: string[],
        id: string,
      }[],
    }[],
  },
};

type SectionRefProps = {
  onCTAClick: () => void,
  sectionRef:
  ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined,
};

const AboutUs: React.FC<SectionRefProps> = ({ sectionRef, onCTAClick }) => {
  const { allContentfulSection }: QueryData = useStaticQuery(graphql`
    query TeamMembers {
      allContentfulSection(filter: {title: {eq: "Team"}}) {
        nodes {
          title
          subtitle
          content {
            ... on ContentfulEmployee {
              briefDescription {
                briefDescription
              }
              name
              picture {
                gatsbyImageData(width: 400, quality: 50, placeholder: BLURRED)
              }
              skills
              id
            }
          }
        }
      }
    }
  `);

  const { nodes: [{ title, subtitle, content }] } = allContentfulSection;

  return (
    <BackgroundButterscotch ref={sectionRef}>
      <PositionMainRelative />
      <Container id='team'>
        <HeadlineContainer>
          <PageHeadline light>{title}</PageHeadline>
          <SmallHeadline light>{subtitle}</SmallHeadline>
        </HeadlineContainer>
        <TeamMemberContainer>
          {content.map(({
            briefDescription: { briefDescription },
            name,
            skills,
            picture: { gatsbyImageData },
            id,
          }) =>
            <TeamMember
              image={gatsbyImageData}
              name={name}
              key={name}
              tags={skills}
              quote={briefDescription}
              showButton={id === 'd8f957b0-db22-54d5-8b53-d407a1ab3deb'}
              buttonHandler={onCTAClick}
            />)
          }
        </TeamMemberContainer>
      </Container>
    </BackgroundButterscotch>
  );
};

export default AboutUs;
