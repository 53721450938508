import React from 'react';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { Icon, ValueName, ValueContainer, ValueText } from './components';

type ValueProps = {
  image: IGatsbyImageData,
  name: string,
  text: string,
};

// eslint-disable-next-line @typescript-eslint/no-extra-parens
const Value: React.FC<ValueProps> = ({ image, name, text }) => (
  <ValueContainer>
    <Icon image={getImage(image)!} alt='Value' />
    <ValueName>{name}</ValueName>
    <ValueText>{text}</ValueText>
  </ValueContainer>
);

export default Value;
