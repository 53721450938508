import { ToastOptions } from 'react-toastify';
import defaultTheme from './theme';

type ToastStyles = {
  loading: ToastOptions,
  error: ToastOptions,
  success: ToastOptions,
};

const toastStyle: ToastStyles = {
  loading: {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'dark',
    style: {
      backgroundColor: defaultTheme.color.basic.silver,
      fontFamily: `${defaultTheme.typography.akkurat}, sans-serif`,
      color: defaultTheme.color.basic.black,
    },
  },
  error: {
    delay: 300,
    icon: false,
    style: {
      backgroundColor: defaultTheme.color.butterscotch.main,
      color: defaultTheme.color.basic.white,
      fontFamily: `${defaultTheme.typography.akkurat}, sans-serif`,
    },
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'dark',
  },
  success: {
    delay: 300,
    icon: '🚀',
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'dark',
    style: {
      backgroundColor: defaultTheme.color.basic.silver,
      fontFamily: `${defaultTheme.typography.akkurat}, sans-serif`,
      color: defaultTheme.color.basic.black,
    },
  },
};

export default toastStyle;
