import { motion, TargetAndTransition, VariantLabels } from 'framer-motion';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import theme from '../../styles/theme';

type ButtonProps = {
  height?: string,
  width?: string,
  primary?: boolean,
  outlined?: boolean,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  whileHover?: VariantLabels | TargetAndTransition,
  whileTab?: VariantLabels | TargetAndTransition,
  style?: { opacity: string } | { marginBottom: string },
  mobile?: boolean,
  disabled?: boolean,
};

const ContactFormButton = styled(motion.button)<ButtonProps>`

${breakpoints.xs`
  display: ${({ mobile }) => (mobile ? 'block' : 'none')};
  height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : '40px')};
  padding: 0 25px 0 25px;
  font-size: 14px;
  margin-top: 15px;
`}
${breakpoints.md`
  display: ${({ mobile }) => (mobile ? 'none' : 'block')};
  margin-top: unset;
`}
${breakpoints.lg`
  height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : '50px')};
  padding: 0 20px 0 20px;
  font-size: 16px;
`}
  width: 100%;
  background: ${({ primary }) => (primary ? `linear-gradient(259deg, ${theme.color.butterscotch.tint}, ${theme.color.butterscotch.main}, ${theme.color.butterscotch.shade})` : `transparent`)};
  opacity: ${({ disabled }) => (disabled && 0.35)};
  background-size: 200%;
  background-position: 100% 0;
  border: ${({ primary }) => (primary ? 'none' : `${theme.color.hamam.main} solid 0px `)};;
  border-radius: 26px;
  box-shadow: ${({ primary }) => (primary ? `0 5px 10px 3px rgba(166, 50, 69, 0.1)` : `none`)};
  text-transform: uppercase;
  font-family: ${theme.typography.akkurat}, sans-serif;
  color: ${({ primary }) => (primary ? theme.color.basic.white : theme.color.hamam.main)};
  color: ${({ outlined }) => outlined && theme.color.hamam.main};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  text-align: center;
  cursor: pointer;
  transition:
    ${({ primary }) => (primary ? 'background .5s ease-in-out' : 'border .1s ease-in-out')},
    box-shadow .1s ease-in-out
  ;

  &:focus, &:hover {
    background-position: 0 0;
    border: ${({ primary }) => (primary ? 'none' : `${theme.color.hamam.main} solid 4px `)};
    box-shadow: ${({ primary }) => (primary ? `0 5px 10px 3px rgba(166, 50, 69, 0.1)` : `0 5px 10px 3px rgba(166, 50, 69, 0.1)`)};
  }
`;

const MobileCloseButton = styled.button<ButtonProps>`
  ${breakpoints.lg`
    display: none;
    height: 0px;
    width: 0px;
`}
  position: absolute;
  right: 25px;
  top: 25px;
  width: 35px;
  height: 35px;
  opacity: 1;
  border: 1px solid transparent;
  background-color: transparent;

  :after, :before {
  position: absolute;
  content: ' ';
  top: -3px;
  height: 35px;
  width: 2px;
  border-radius: 5px;
  background-color: ${theme.color.basic.black};
  }

  :before {
    transform: rotate(45deg);
  }

  :after {
    transform: rotate(-45deg);
  }
`;

const ButtonClose = styled.img`
  ${breakpoints.xs`
    display: block;
  `}
  ${breakpoints.md`
    display: none;
  `}
  position: absolute;
  width: 40px;
  right: 15px;
`;

export {
  ContactFormButton,
  ButtonClose,
  MobileCloseButton,
};
