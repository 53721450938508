/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { BackgroundButterscotch, BackgroundDarken } from '../../components/components';

import { ButtonOutlined, Button } from '../../components/buttons/index';
import { PageHeadline, SmallHeadline } from '../../components/headlines/index';
import Container from './components';

type QueryData = {
  allContentfulSection: {
    nodes: {
      title: string,
      buttons: {
        title: string,
        isButtonOutlined: string,
        link: string,
      }[],
    }[],
  },
};

const Linktree: React.FC = () => {
  const { allContentfulSection }: QueryData = useStaticQuery(graphql`
    query {
      allContentfulSection(filter: {title: {eq: "Links"}}) {
        nodes {
          title
          buttons {
            title
            isButtonOutlined
            link
          }
        }
      }
    }
  `);

  const { nodes: [{ buttons }] } = allContentfulSection;

  return (
    <BackgroundButterscotch>
      <BackgroundDarken>
        <Container >
          <PageHeadline light>Links</PageHeadline>
          <SmallHeadline light>Aktuelles von uns</SmallHeadline>
          {buttons.map((button, index) =>
            <a key={index} href={button.link} target='_blank' rel='noreferrer'>
              {button.isButtonOutlined ? (
                <ButtonOutlined
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {button.title}
                </ButtonOutlined>
              ) : (
                <Button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {button.title}
                </Button>
              )
              }
            </a>,
          )}
        </Container>
      </BackgroundDarken>
    </BackgroundButterscotch>
  );
};

export default Linktree;
