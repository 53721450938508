import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { BackgroundButterscotch, BackgroundDarken } from '../../components/components';
import Value from '../../components/value/value';
import {
  OurValuesHeadline,
  Container,
  ValuesContainer,
} from './components';

type QueryData = {
  allContentfulSection: {
    nodes: {
      subtitle: string,
      content: {
        name: string,
        description: {
          description: string,
        },
        image: {
          gatsbyImageData: IGatsbyImageData
          ,
        },
      }[],
    }[],

  },
};

const ValuesSection: React.FC = () => {
  const { allContentfulSection }: QueryData = useStaticQuery(
    graphql`
      query Values {
        allContentfulSection(filter: {subtitle: {eq: "UNSERE WERTE"}}) {
          nodes {
            subtitle
            content {
              ... on ContentfulValues {
                name
                description {
                  description
                }
                image {
                  gatsbyImageData(width: 500, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
  `);

  const { nodes: [{ subtitle, content }] } = allContentfulSection;

  return (
    <BackgroundButterscotch>
      <BackgroundDarken>
        <Container>
          <OurValuesHeadline light>{subtitle}</OurValuesHeadline>
          <ValuesContainer>
            {content.map(
              (
                { name, description: { description }, image: { gatsbyImageData } },
              ) => <Value key={name} image={gatsbyImageData} name={name} text={description} />)
            }
          </ValuesContainer>
        </Container>
      </BackgroundDarken>
    </BackgroundButterscotch>
  );
};
export default ValuesSection;
