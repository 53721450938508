import styled from 'styled-components';

import { MotionStyle, MotionValue } from 'framer-motion';
import breakpoints from '../../styles/breakpoints';
import { BackgroundButterscotch } from '../../components/components';
import { AnimatedDiv } from '../../components/background-image/background-image';

type LogoProps = {
  key: string,
  src: string,
  alt: string,
  loading: string,
};

type AnimatedRocketDivProps = {
  style: { y: MotionValue<number>, x: MotionValue<number> },
};

type CustomerSectionBackgroundProps = {
  style: React.CSSProperties,
  ref?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null,
};

const Container = styled.div<{ id: string }>`
  display: grid;
  position: relative;
  bottom: 0;
  left: 0;
  min-height: 100vh;
  place-items: start center;

  ${breakpoints.xs`
    grid-template-rows: 150px 1fr;
  `}
  ${breakpoints.lg`
    grid-template-rows: 250px 1fr;
  `}
  ${breakpoints.xl`
    grid-template-rows: 350px 1fr;
  `}
`;
const CustomerGrid = styled.div`
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  place-items: center;
  justify-content: space-evenly;
  align-content: space-around;
  margin-bottom: 70px;

  ${breakpoints.xs`
    width: 200px;
    margin-bottom: 50px;
  `}
  ${breakpoints.sm`
    width: 500px;
  `}
  ${breakpoints.md`
    margin-bottom: 70px;
  `}
  ${breakpoints.lg`
    width: 1050px;
  `}
`;

const Logo = styled.img<LogoProps>`
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 50px;
  ${breakpoints.xs`
    width: 100px;
    height: 75px;
  `}
  ${breakpoints.lg`
    width: 150px;
    height: 100px;
  `}
`;

const CustomerSectionBackground = styled(BackgroundButterscotch)<CustomerSectionBackgroundProps>`
  position: relative;
  min-height: 100vh;
`;

const AnimatedCloudMiddleDiv = styled(AnimatedDiv)<{style?: MotionStyle}>`
  ${breakpoints.xs`
    height: unset;
    bottom: -350px;
  `}
  ${breakpoints.md`
    min-height: 133vh;
  `}
  ${breakpoints.lg`
    min-height: 100vh;
    bottom: -300px;
  `}
  top: unset;
  transition: all 0.7s ease-out;
`;

const AnimatedCloudFrontDiv = styled(AnimatedDiv)<{style?: MotionStyle}>`
  ${breakpoints.xs`
    height: unset;
    bottom: -280px;
  `}
  ${breakpoints.md`
    min-height: 133vh;
  `}
  ${breakpoints.lg`
    min-height: 100vh;
    bottom: -270px;
  `}
  top: unset;
  transition: all 0.7s ease-out;
`;

const AnimatedRocketDiv = styled(AnimatedDiv)<AnimatedRocketDivProps>`
  ${breakpoints.xs`
    height: unset;
    bottom: -360px;
    left: -80px;
  `}
  ${breakpoints.md`
    min-height: 133vh;
  `}
  ${breakpoints.lg`
    min-height: 100vh;
    bottom: -200px;
    left: -80px;
  `}
  top: unset;
  transition: all 0.7s ease-out;
`;

export {
  Container,
  CustomerGrid,
  Logo,
  AnimatedCloudMiddleDiv,
  AnimatedCloudFrontDiv,
  AnimatedRocketDiv,
  CustomerSectionBackground,
};
