import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {
  BackgroundButterscotch,
} from '../../components/components';
import { SmallHeadline, PageHeadline, SectionHeadline } from '../../components/headlines/index';
import { Container, HeadlineContainer, ContentContainer, TextContainer, CardText, CardImage, CardContainer } from './components';

type QueryData = {
  allContentfulSection: {
    nodes: {
      title: string,
      subtitle: string,
      content: {
        name: string,
        description: {
          description: string,
        },
        image: {
          gatsbyImageData: IGatsbyImageData,
        },
      }[],
    }[],
  },
};

const DevService: React.FC = () => {
  const { allContentfulSection }: QueryData = useStaticQuery(graphql`
    query {
      allContentfulSection(filter: {title: {eq: "Softwareentwicklung"}}) {
        nodes {
          title
          subtitle
          content {
            ... on ContentfulServices {
              name
              description {
                description
              }
              image {
                gatsbyImageData(width: 400,
                  quality: 50,
                  placeholder: TRACED_SVG,
                )
              }
            }
          }
        }
      }
    }
  `);

  const { nodes: [{ title, subtitle, content }] } = allContentfulSection;

  return (
    <BackgroundButterscotch>
      <Container id='service'>
        <HeadlineContainer>
          <PageHeadline light>{title}</PageHeadline>
          <SmallHeadline light>{subtitle}</SmallHeadline>
        </HeadlineContainer>
        <ContentContainer>
          {content.map((data) => (
            <CardContainer key={data.name}>
              <CardImage image={getImage(data.image.gatsbyImageData)!} alt={`image of ${data.name}`} />
              <TextContainer>
                <SectionHeadline>{data.name}</SectionHeadline>
                <CardText>
                  {data.description.description}
                </CardText>
              </TextContainer>
            </CardContainer>
          ))}
        </ContentContainer>
      </Container>
    </BackgroundButterscotch>
  );
};

export default DevService;
