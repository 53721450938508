/* eslint-disable max-lines */
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 10vw;

  ${breakpoints.lg`
    padding: 0 5vw;
  `}
`;

const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  display: block;

  ${breakpoints.lg`
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 5vw;
  `}
`;

const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 7vh 0 0 0;
  text-align: center;

  ${breakpoints.lg`
    text-align: left;
  align-items: flex-start;
    margin: 0;
    width: 42.5vw;
  `}
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  ${breakpoints.lg`
    width: 42.5vw;
  `}
`;
const TitleImage = styled(GatsbyImage)`
  height: inherit;
  align-self: center;
  object-fit: cover;
  border-radius: 10px;
`;

const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.typography.mosk.medium};
  font-weight: 500;
  text-align: center;
  margin-bottom: 25px;
  letter-spacing: 1.5px;

  ${breakpoints.lg`
    font-size: 18px;
    text-align: left;
    line-height: 24px;
  `}
  ${breakpoints.xl`
    font-size: 24px;
    line-height: 32px;
    letter: 1.5px;
  `}
`;

const HighlightText = styled.div`
  font-family: ${({ theme }) => theme.typography.mosk.bold};
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 25px;
  letter-spacing: 1.5px;

  ${breakpoints.lg`
    font-size: 32px;
    line-height: 43px;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;

export {
  Container,
  ContentContainer,
  ButtonContainer,
  TextContainer,
  ImageContainer,
  TitleImage,
  Subtitle,
  HighlightText,
};
