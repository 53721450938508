/* eslint-disable complexity */
/* eslint-disable import/max-dependencies */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../layout';
import Seo from '../seo';
import NotFound from '../../containers/not-found/not-found';
import Contact from '../../containers/contact/contact';
import AboutUs from '../../containers/about-us/about-us';
import Mission from '../../containers/mission-section/mission-section';
import HRSection from '../../containers/hr-section/hr-section';
import Values from '../../containers/values-section/values-section';
import Imprint from '../../containers/imprint/imprint';
import PrivacyPolicy from '../../containers/privacy-policy/privacy-policy';
import Linktree from '../../containers/linktree/linktree';
import BlogOverview from '../../containers/blog-overview/blog-overview';
import Platforms from '../../containers/platforms/platforms';
import Services from '../../containers/services/service';
import DevServices from '../../containers/services/dev-services';
import ProcessSection from '../../containers/process/process';
import ContactSection from '../../containers/contact-section/contact-section';
import WhatWeDoSection from '../../containers/what-we-do/what-we-do';
import TechnologiesSection from '../../containers/technologies/technologies';
import HeroSection from '../../containers/hero-section/hero-section';
import NavBar from '../navbar/navbar';
import Footer from '../footer/footer';
import CustomerSection from '../../containers/customers/customers';
import DevTeamsHeroShot from '../dev-team-hero-shot/hero-shot';
import DevTeamsIntroduction from '../dev-team-introduction/dev-team-introduction';
import DevTeamsFoundersQuote from '../dev-teams-founders-quote/dev-teams-founders-quote';
import DevTeamsPlatformQuote from '../dev-team-platforms-quote/platforms';

type SectionComponentsProps = {
  filter: string,
};

type QueryData = {
  allContentfulPage: {
    nodes: {
      title: string,
      light: boolean,
      sections: {
        title: string,
        id: string,
      }[],
      seo: {
        title: string,
        description: string,
        image: {
          file: {
            url: string,
          },
        },
        contentType: string,
      },
    }[],
  },
};

const SectionComponent: React.FC<SectionComponentsProps> = ({ filter }) => {
  const aboutUsRef = React.useRef<HTMLDivElement>(null);
  const hrRef = React.useRef<HTMLDivElement>(null);
  const [aboutUsContainerHeight, setAboutUsContainerHeight] = React.useState(0);
  const whatWeDoSection = React.useRef<HTMLDivElement>(null);
  const customerSection = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setAboutUsContainerHeight(aboutUsRef.current?.scrollHeight ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aboutUsRef.current]);

  const windowHeight = typeof window === 'undefined' ? 0 : window.innerHeight;

  const handleWhatWeDoClick = () => {
    window.scrollTo({ top: windowHeight, behavior: 'smooth' });
  };

  const scrollToHr = () => {
    hrRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };
  const {
    allContentfulPage,
  }: QueryData = useStaticQuery(
    graphql`
      query {
        allContentfulPage{
          nodes {
            title
            light
            sections {
              title
              id
            }
            seo {
              title
              description
              image {
                file {
                  url
                }
              }
              contentType
            }
          }
        }
      }
    `,
  );

  const index = allContentfulPage.nodes.findIndex((x: { title: string }) => x.title === filter);
  const { sections } = allContentfulPage.nodes[index];

  const indexWhatWeDoSection = sections.findIndex((x: { id: string }) => x.id === '2be6a03d-e2bd-53dd-8d36-df513bebe70d') + 1;
  const indexCustomerSection = sections.findIndex((x: { id: string }) => x.id === 'eeb1a44b-79ee-534f-87ec-7a575c0ab1bc') + 1;

  return (
    <Layout>
      <Seo
        title={allContentfulPage.nodes[index].seo.title}
        description={allContentfulPage.nodes[index].seo.description}
        image={allContentfulPage.nodes[index].seo.image.file.url}
        contentType={allContentfulPage.nodes[index].seo.contentType}
      />
      <NavBar light={allContentfulPage.nodes[index].light} />
      {sections.map((section) => {
        switch (section.id) {
          case '2bb5a1a7-45d0-5cd6-a6a9-cde3ffa34431':
            return <NotFound key={section.title} />;
          case 'e4060249-24eb-580d-a4e8-23ef6414c5d8':
            return <Contact key={section.title} />;
          case '2050122f-0d3b-5915-bd35-e9d3648ef8e5':
            return <AboutUs sectionRef={aboutUsRef} key={section.title} onCTAClick={scrollToHr} />;
          case 'fb5e14c2-0bb2-565f-bf7d-3f96435238d5':
            return <Mission previousContainerHeight={aboutUsContainerHeight} key={section.title} />;
          case 'e4f203d0-e876-52f2-8252-14eab74a943c':
            return <Values key={section.title} />;
          case 'ec2ee148-2b7d-5b16-95a4-8628e257234b':
            return <HRSection key={section.title} scrollRef={hrRef} />;
          case '1cbba0bc-10af-5d68-8563-60a573a3bcae':
            return <Imprint key={section.title} />;
          case '1c59cbd3-c5e1-5df8-9353-4dd2ace9c06e':
            return <PrivacyPolicy key={section.title} />;
          case '884d4045-7467-53eb-9e8b-b36cb971e3e4':
            return <Linktree key={section.title} />;
          case '10312155-a0e1-549a-8a1a-a8b6c4b5a88f':
            return <BlogOverview key={section.title} />;
          case '6661cfe0-6e3a-5004-82a2-9ee87d8e8f48':
            return <Services key={section.title} />;
          case '9d62cbe1-01c3-54d3-a220-c6b869845d8d':
            return <DevServices key={section.title} />;
          case '5fa61ffc-d92a-587f-8583-68a063201194':
            return <Platforms key={section.title} />;
          case '6eac0b51-cb1f-54c7-8376-74dbc2c8c355':
            return <ContactSection key={section.title} dark trackingId='leistungen-s3' />;
          case '89539881-a5a9-548d-8c3d-262b4ff1289e':
            return <HeroSection key={section.title} handleWhatWeDoClick={handleWhatWeDoClick} />;
          case '2be6a03d-e2bd-53dd-8d36-df513bebe70d':
            return (
              <WhatWeDoSection
                key={section.title}
                sectionRef={whatWeDoSection}
                sectionNumber={indexWhatWeDoSection}
              />
            );
          case 'eeb1a44b-79ee-534f-87ec-7a575c0ab1bc':
            return (
              <CustomerSection
                key={section.title}
                sectionRef={customerSection}
                sectionNumber={indexCustomerSection}
              />
            );
          case 'd2e7968e-7404-5768-b312-2e63c723034e':
            return <ProcessSection key={section.title} />;
          case 'ed544f83-b808-531b-8375-f4a1d22173b9':
            return <TechnologiesSection key={section.title} />;
          case '0a1f157f-d511-517f-a892-1409726db0dd':
            return <ContactSection key={section.title} trackingId='leistungen-s3' />;
          // landing page dev teams - sections
          case 'b67a49e7-60a6-500e-92be-200534717f3e':
            return <DevTeamsHeroShot key={section.title} />;
          case 'a10dcc3a-8261-56a7-918d-284153f321ae':
            return <DevTeamsIntroduction key={section.title} />;
          case '445535af-2a76-5cc5-b44b-924c252253df':
            return <DevTeamsFoundersQuote key={section.title} />;
          case 'a695fa4e-ea55-517d-a4ed-b757cbffeca8':
            return <DevTeamsPlatformQuote key={section.title} />;
          default:
            // console.log(JSON.stringify(section));
            return 'error';
        }
      })
      }
      <Footer light />
    </Layout>
  );
};

export default SectionComponent;
