import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { BackgroundSilver, BackgroundDarken } from '../../components/components';
import { PageHeadline, SmallHeadline } from '../../components/headlines/index';
import BlogCard from '../../components/blog-card/blog-card';
import { Container, ContentContainer } from './components';

type QueryData = {
  allContentfulSection: {
    nodes: {
      content: {
        link: string,
        subtitle: string,
        title: string,
        shortText: {
          shortText: string,
        },
        contentMedia: {
          id: string,
          gatsbyImageData: IGatsbyImageData,
        }[],
      }[],
    }[],
  },
};

const BlogOverview: React.FC = () => {
  const { allContentfulSection }: QueryData = useStaticQuery(graphql`
    query {
      allContentfulSection(filter: {title: {eq: "Blog"}}) {
        nodes {
          content {
            ... on ContentfulBlogPost {
              link
              subtitle
              title
              shortText {
                shortText
              }
              contentMedia {
                id
                gatsbyImageData(quality: 100, width: 3000, height: 1000)
              }
            }
          }
        }
      }
    }
  `);

  const { nodes: [{ content }] } = allContentfulSection;

  return (
    <BackgroundSilver>
      <BackgroundDarken>
        <Container>
          <div>
            <PageHeadline>Blog</PageHeadline>
            <SmallHeadline>Unsere Publikationen</SmallHeadline>
          </div>
          <ContentContainer>
            {
              content.map(
                (
                  { link, title, subtitle, shortText, contentMedia: [{ gatsbyImageData }] },
                  index,
                ) =>
                  (
                    <BlogCard
                      key={link}
                      title={title}
                      subtitle={subtitle}
                      excerpt={shortText.shortText}
                      link={link}
                      isOdd={Boolean(!(index % 2))}
                      image={gatsbyImageData}
                    />
                  ),
              )}
          </ContentContainer>
        </Container>
      </BackgroundDarken>
    </BackgroundSilver>
  );
};

export default BlogOverview;
