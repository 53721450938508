import styled from 'styled-components';
import { PitchHeadline } from '../../components/headlines/index';

import { AnimatedDiv } from '../../components/background-image/background-image';
import breakpoints from '../../styles/breakpoints';
import { BackgroundSilver } from '../../components/components';

const ButtonContainer = styled.div`
  display: grid;

  ${breakpoints.xs`
    grid-gap: 20px;
    grid-template-rows: 40px 40px;
    place-self: start center;
  `}
  ${breakpoints.sm`
    margin-top: 45px;
    grid-template-rows: auto;
    grid-template-columns: auto auto;
  `}
  ${breakpoints.md`
    margin-top: unset;
    grid-template-columns: unset;
  `}
  ${breakpoints.lg`
    grid-gap: 40px;
    grid-template-columns: 260px 260px;
    place-self: center end;
  `}
`;

const WhatWeDoHeadline = styled(PitchHeadline)`
  ${breakpoints.sm`
    width: 400px;
    margin-top: 50px;
  `}
  ${breakpoints.md`
    width: 380px;
    font-size: 40px;
    margin-top: 130px;
  `}
  ${breakpoints.lg`
    width: 800px;
    font-size: 64px;
    margin-top: 100px;
  `}
  ${breakpoints.xl`
    width: 1300px;
    font-size: 80px;
  `}
`;

type WhatWeDoSectionBackgroundProps = {
  style?: object,
  ref:
  ((instance: HTMLDivElement | null) => void) |
  React.RefObject<HTMLDivElement> |
  null |
  undefined,
};

const WhatWeDoSectionBackground = styled(BackgroundSilver)<WhatWeDoSectionBackgroundProps>`
  position: relative;
`;

const AnimatedRocketDiv = styled(AnimatedDiv)`
  ${breakpoints.xs`
    bottom: -155px;
  `}
  ${breakpoints.sm`
    bottom: -360px;
    opacity: 0.6;
  `}
  ${breakpoints.md`
    bottom: -300px;
    opacity: 1;
  `}
  ${breakpoints.lg`
    bottom: -340px;
  `}
  top: unset;
  transition: all 0.7s ease-out;
`;

const AnimatedCloudDiv = styled(AnimatedDiv)`
  ${breakpoints.xs`
    bottom: -170px;
  `}
  ${breakpoints.sm`
    bottom: -400px;
    opacity: 0.7;
  `}
  ${breakpoints.md`
    bottom: -300px;
    opacity: 1;
  `}
  ${breakpoints.lg`
    bottom: -370px;
  `}
  top: unset;
  transition: all 0.7s ease-out;
`;

export {
  ButtonContainer,
  WhatWeDoSectionBackground,
  WhatWeDoHeadline,
  AnimatedRocketDiv,
  AnimatedCloudDiv,
};
