/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-extra-parens */
import React, { RefObject } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { useViewportScroll, useTransform } from 'framer-motion';
import { getImage, withArtDirection } from 'gatsby-plugin-image';
import usePlausible from '../../hooks/use-plausible';
import { BackgroundDarken } from '../../components/components';
import { PitchContainer } from '../../components/content-container/index';
import { Button } from '../../components/buttons/index';
import { IGatsbyImageDataProps } from '../../types/image-types';
import AnimatedGatsbyImage from '../../components/animated-gatsby-image/animated-gatsby-image';
import { ButtonContainer, WhatWeDoSectionBackground, WhatWeDoHeadline, AnimatedRocketDiv, AnimatedCloudDiv } from './components';

type ValueProps = {
  sectionRef:
  ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined,
  sectionNumber: number,
};

type QueryData = {
  frontTwo: IGatsbyImageDataProps,
  middleTwo: IGatsbyImageDataProps,
  backTwo: IGatsbyImageDataProps,
  frontTwoMobile: IGatsbyImageDataProps,
  middleTwoMobile: IGatsbyImageDataProps,
  backTwoMobile: IGatsbyImageDataProps,
  allContentfulSection: {
    nodes: {
      title: string,
      subtitle: string,
      buttons: {
        title: string,
        link: string,
      }[],
    }[],
  },
};

const WhatWeDoSection: React.FC<ValueProps> = ({ sectionRef, sectionNumber }) => {
  const { plausible } = usePlausible();

  const {
    frontTwo,
    middleTwo,
    backTwo,
    frontTwoMobile,
    backTwoMobile,
    middleTwoMobile,
    allContentfulSection,
  }: QueryData = useStaticQuery(
    graphql`
      query {
        frontTwo: file(relativePath: { eq: "section-two-bg-front.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 4160,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        middleTwo: file(relativePath: { eq: "section-two-bg-middle.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 4160,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        backTwo: file(relativePath: { eq: "section-two-bg-back.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 4160,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        frontTwoMobile: file(relativePath: { eq: "section-two-bg-front-mobile.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 490,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        middleTwoMobile: file(relativePath: { eq: "section-two-bg-middle-mobile.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 4160,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        backTwoMobile: file(relativePath: { eq: "section-two-bg-back-mobile.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 4160,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        allContentfulSection(filter: {title: {eq: "Elevator Pitch"}}) {
          nodes {
            title
            subtitle
            buttons {
              title
              link
            }
          }
        }
      }
    `,
  );

  const { nodes: [{ subtitle, buttons }] } = allContentfulSection;

  const frontSourcesTwo = withArtDirection(getImage(frontTwo.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(frontTwoMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);
  const middleSourcesTwo = withArtDirection(getImage(middleTwo.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(middleTwoMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);
  const backSourcesTwo = withArtDirection(getImage(backTwo.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(backTwoMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);

  const { scrollY } = useViewportScroll();
  const windowWidth = typeof window === 'undefined' ? 0 : window.innerWidth;
  const windowHeight = typeof window === 'undefined' ? 0 : window.innerHeight;
  const windowStart = sectionNumber * windowHeight;

  let endAnimation = windowStart - (windowHeight * 0.6);
  let startAnimation = endAnimation - windowHeight;

  if (windowWidth <= 480) {
    startAnimation = windowStart - (windowHeight * 1.1);
    endAnimation = windowStart - (windowHeight * 0.8);
  }

  const rocketStart = ((endAnimation - startAnimation) / 2) + startAnimation;

  const yrocket = useTransform(scrollY,
    [startAnimation, rocketStart, endAnimation], [0, -80, -270]);

  const ycloudmiddle = useTransform(scrollY,
    [startAnimation, endAnimation], [-50, -250]);
  const scalecloudmiddle = useTransform(scrollY, [startAnimation, endAnimation], [1, 1.1]);

  const ycloudfront = useTransform(scrollY,
    [startAnimation, rocketStart, endAnimation], [0, -150, -245]);
  const xcloudfront = useTransform(scrollY,
    [startAnimation, endAnimation], [0, -30]);
  const scalecloudfront = useTransform(scrollY, [startAnimation, endAnimation], [1, 1.3]);

  return (
    <WhatWeDoSectionBackground style={{ overflow: 'hidden', position: 'relative' }} ref={sectionRef} id='whatWeDo'>
      <BackgroundDarken >

        <AnimatedRocketDiv style={{ y: yrocket }}>
          <AnimatedGatsbyImage image={getImage(backSourcesTwo)!} alt='rocket' />
        </AnimatedRocketDiv>

        <AnimatedCloudDiv style={{ y: ycloudmiddle, scale: scalecloudmiddle }!}>
          <AnimatedGatsbyImage image={getImage(middleSourcesTwo)!} alt='cloud middle' />
        </AnimatedCloudDiv>

        <AnimatedCloudDiv
          style={{ y: ycloudfront, x: xcloudfront, scale: scalecloudfront }!}
        >
          <AnimatedGatsbyImage image={getImage(frontSourcesTwo)!} alt='cloud front' />
        </AnimatedCloudDiv>

        <PitchContainer>
          <WhatWeDoHeadline>{subtitle}</WhatWeDoHeadline>
          <ButtonContainer>
            <div />
            <Button
              type='button'
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              primary
              onClick={() => {
                navigate(buttons[0].link);
                plausible('start-s2-button-leistungen');
              }}
            >
              {buttons[0].title}
            </Button>
          </ButtonContainer>
        </PitchContainer>
      </BackgroundDarken>
    </WhatWeDoSectionBackground>
  );
};

export default WhatWeDoSection;
