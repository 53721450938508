import { motion, TargetAndTransition, VariantLabels } from 'framer-motion';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import theme from '../../styles/theme';

type ButtonProps = {
  height?: string,
  width?: string,
  primary?: boolean,
  outlined?: boolean,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  whileHover?: VariantLabels | TargetAndTransition,
  whileTab?: VariantLabels | TargetAndTransition,
  style?: { opacity: string } | { marginBottom: string },
  mobile?: boolean,
  disabled?: boolean,
};

const ContactButton = styled(motion.button)<ButtonProps>`

${breakpoints.xs`
  min-height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : '40px')};
  padding: 7px 25px 7px 25px;
  font-size: 14px;
`}
${breakpoints.lg`
  width: ${({ width }: {width?: Pick<ButtonProps, 'width'>}) => (width ? width : '50%')};
  height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : '50px')};
  padding: 0 20px 0 20px;
  font-size: 16px;
`}
  display: block;
  width: 100%;
  background: ${({ primary }) => (primary ? `${theme.color.butterscotch.main}` : `transparent`)};
  opacity: ${({ disabled }) => (disabled && 0.35)};
  background-size: 200%;
  background-position: 100% 0;
  border: ${({ primary }) => (primary ? 'none' : `${theme.color.hamam.main} solid 2px `)};;
  border-radius: 26px;
  box-shadow: ${({ primary }) => (primary ? `0 5px 10px 3px rgba(166, 50, 69, 0.1)` : `none`)};
  text-transform: uppercase;
  font-family: ${theme.typography.akkurat}, sans-serif;
  color: ${({ primary }) => (primary ? theme.color.basic.white : theme.color.hamam.main)};
  color: ${({ outlined }) => outlined && theme.color.hamam.main};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  text-align: center;
  cursor: pointer;
  transition: ${({ primary }) => (primary ? 'background .5s ease-in-out, color .5s ease-in-out, box-shadow .5s ease-in-out' : 'border .1s ease-in-out, color .5s ease-in-out, box-shadow .1s ease-in-out')
};

  &:focus, &:hover {
    //background-position: 0 0;
    box-shadow: ${({ primary }) => (primary ? `0 5px 10px 3px rgba(166, 50, 69, 0.1)` : `0 5px 10px 3px rgba(166, 50, 69, 0.1)`)};
  }
`;

export default ContactButton;
