import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  display: grid;
  min-height: 100vh;
  place-items: center;
  grid-template-columns: 1fr;
  ${breakpoints.xs`
      grid-template-rows: 1fr 3fr;
  `}
  ${breakpoints.lg`
      grid-template-rows: 1fr 2fr;
  `}
`;

const ContactContainer = styled.div`
  ${breakpoints.xs`
    display: block;
    padding: 5vh 10vw;
    width: 80vw;
  `}
  ${breakpoints.md`
    padding: 5vh 20vw;
    width: 60vw;
  `}
  ${breakpoints.lg`
    position: relative;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 750px;
  `}
  ${breakpoints.xl`
    width: 900px;
  `};
`;

const ContactContentContainer = styled.div`
${breakpoints.xs`
  width: 100%;
  padding: 0;
  margin-top: 20px;
`}
${breakpoints.md`
  margin-top: 30px;
  padding-left: 20px;
`}
${breakpoints.lg`
  width: 420px;
  margin-left: 60px;
  margin-top: -50px;
  height: 300px;
`}
${breakpoints.xl`
  width: 480px;
  padding-left: 25px;
  height: 375px;
`}
  display: flex;
  flex-direction: column;
  height: inherit;
`;

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ContactHeadlineContainer = styled.div<{mobile?: boolean}>`
  ${breakpoints.xs`
    margin-bottom: 100px;
    display: ${({ mobile }) => (mobile ? 'block' : 'none')};
  `}
  ${breakpoints.md`
    margin-bottom: 125px;
  `}
  ${breakpoints.lg`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: inherit;
    height: 150px;
    margin-bottom: 0px;
    display: ${({ mobile }) => (mobile ? 'none' : 'block')};
  `}
  ${breakpoints.xl`
    height: 187.5px;
  `}
`;

const ContentContainer = styled.div`
  display: grid;
  height: 40%;
  place-items: start center;

  ${breakpoints.xs`
    grid-template-columns: 1fr;
    width: 80%;
    margin-bottom: 100px;
  `}
  ${breakpoints.lg`
    grid-template-rows: 1fr 2fr;
    grid-template-columns: repeat(2, 1fr);
    width: 50%;
  `}
`;

const TextContainer = styled.div`
  ${breakpoints.xs`
    width: 100%;
  `}
  ${breakpoints.lg`
    width: 100%;
    height: 50px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 15px;
    text-align: left;
    margin: 10px 0;
  `}
  ${breakpoints.xl`
    margin: 20px 0;
  `}
  margin: 20px 0;
`;

const ContactTextContainer = styled.div`
    display: flex;
  ${breakpoints.xs`
    height: auto;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    `}

  ${breakpoints.lg`
    text-align: none;
    height: 150px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    & > *:first-child {
      margin-bottom: 0;
    }
    `};
  ${breakpoints.xl`
    height: 187.5px;
    `}
    width: 100%;
`;

const ContactPerson = styled(GatsbyImage)`
  ${breakpoints.sm`
    width: 100%;
  `}
  ${breakpoints.lg`
    width: 350px;
  `};
  align-self: flex-end;
`;

const ButtonIcon = styled.img`
  ${breakpoints.xs`
    filter: brightness(0) saturate(100%) invert(41%) sepia(56%) saturate(376%) hue-rotate(143deg) brightness(86%) contrast(89%);
  `}
  width: 20px;
  filter: brightness(0) saturate(100%) invert(41%) sepia(56%) saturate(376%) hue-rotate(143deg) brightness(86%) contrast(89%);
`;

const ContactText = styled.p`
  ${breakpoints.xs`
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.2px;
  `}
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.5px;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.white};

  &:last-of-type {
    margin-bottom: 30px;
  }
`;

const ContactImageContainer = styled.div`
  ${breakpoints.xs`
    width: 80vw;
    height: 80vw;
    border-radius: 2vw;
  `};
  ${breakpoints.md`
    width: 50vw;
    height: 50vw;
    left: 5vw;
  `};
  ${breakpoints.lg`
    left: 0;
    width: 250px;
    height: 250px;
    border-radius: 10px;
    margin-left: 0;
  `};
  ${breakpoints.xl`
    width: 325px;
    height: 325px;
  `}
  position: relative;
  bottom: 0;
  display: flex;
  overflow: visible;
  background-image: linear-gradient(to bottom left, ${({ theme }) => theme.color.butterscotch.main} 0%, transparent 100%), url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.75' numOctaves='3' stitchTiles='stitch'/%3E%3CfeColorMatrix values='0'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' fill='%23a63245' opacity='0.4'/%3E%3C/svg%3E");
  background-color: ${({ theme }) => theme.color.butterscotch.alternative};
`;

const ButtonsContainer = styled.div`
  ${breakpoints.xs`
    & > *:first-child {
      margin-bottom: 20px;
    }
  `};
  ${breakpoints.lg`
    display: flex;
    gap: 40px 20px;
    & > *:first-child {
      margin-bottom: 0;
    }
  `}
  display: inline-grid;
  text-align: center;
`;

const BackgroundContactContainer = styled.div`
  ${breakpoints.xs`
    height: auto
  `};
  ${breakpoints.lg`
    height: 100vh;
  `};
  width: 100vw;
  background-image: radial-gradient(circle at center, ${({ theme }) => theme.color.hamam.alternative} 30%, rgba(50, 50, 50, 0.1) 100%), url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='2' numOctaves='3' stitchTiles='stitch'/%3E%3CfeColorMatrix type='saturate' values='0'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' opacity='0.4'/%3E%3C/svg%3E");
  background-color: ${({ theme }) => theme.color.hamam.alternative};
`;

export {
  Container,
  ContactContainer,
  ContactContentContainer,
  HeadlineContainer,
  ContactHeadlineContainer,
  ContactPerson,
  ButtonIcon,
  ContentContainer,
  TextContainer,
  ContactTextContainer,
  ContactText,
  ContactImageContainer,
  ButtonsContainer,
  BackgroundContactContainer,
};
