/* eslint-disable @typescript-eslint/no-extra-parens */
import React, { RefObject } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useViewportScroll, useTransform } from 'framer-motion';
import { getImage, withArtDirection } from 'gatsby-plugin-image';

import AnimatedGatsbyImage from '../../components/animated-gatsby-image/animated-gatsby-image';
import { SmallHeadline } from '../../components/headlines/index';
import { IGatsbyImageDataProps } from '../../types/image-types';
import { CustomerGrid, Container, Logo, AnimatedRocketDiv, AnimatedCloudFrontDiv, AnimatedCloudMiddleDiv, CustomerSectionBackground } from './components';

type ValueProps = {
  sectionRef:
  ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined,
  sectionNumber: number,
};

type queryData = {
  frontThree: IGatsbyImageDataProps,
  middleThree: IGatsbyImageDataProps,
  backThree: IGatsbyImageDataProps,
  frontThreeMobile: IGatsbyImageDataProps,
  middleThreeMobile: IGatsbyImageDataProps,
  backThreeMobile: IGatsbyImageDataProps,
  allContentfulSection: {
    nodes: {
      title: string,
      subtitle: string,
      content: {
        title: string,
        logoImage: {
          file: {
            url: string,
          },
        },
      }[],
    }[],
  },
};

const CustomerSection: React.FC<ValueProps> = ({ sectionRef, sectionNumber }) => {
  const {
    frontThree,
    middleThree,
    backThree,
    frontThreeMobile,
    backThreeMobile,
    middleThreeMobile,
    allContentfulSection,
  }: queryData = useStaticQuery(
    graphql`
      query {
        frontThree: file(relativePath: { eq: "section-three-bg-front.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 4160,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        middleThree: file(relativePath: { eq: "section-three-bg-middle.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 4160,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        backThree: file(relativePath: { eq: "section-three-bg-back.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 4160,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        frontThreeMobile: file(relativePath: { eq: "section-three-bg-front-mobile.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 490,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        middleThreeMobile: file(relativePath: { eq: "section-three-bg-middle-mobile.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 490,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        backThreeMobile: file(relativePath: { eq: "section-three-bg-back-mobile.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 490,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        allContentfulSection(filter: {title: {eq: "Kunden und Partner"}}) {
          nodes {
            title
            subtitle
            content {
              ... on ContentfulCustomers {
                title
                logoImage {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `,
  );

  const { nodes: [{ subtitle, content }] } = allContentfulSection;

  const frontSourcesThree
  = withArtDirection(getImage(frontThree.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(frontThreeMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);
  const middleSourcesThree
  = withArtDirection(getImage(middleThree.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(middleThreeMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);
  const backSourcesThree = withArtDirection(getImage(backThree.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(backThreeMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);

  const { scrollY } = useViewportScroll();
  const windowHeight = typeof window === 'undefined' ? 0 : window.innerHeight;
  const windowWidth = typeof window === 'undefined' ? 0 : window.innerWidth;
  const windowStart = sectionNumber * windowHeight;

  let startAnimation = windowStart - (windowHeight * 1.5);
  let endAnimation = windowStart - (windowHeight * 0.5);

  if (windowWidth <= 480) {
    startAnimation = windowStart - (windowHeight);
    endAnimation = windowStart;
  }
  const xrocket = useTransform(scrollY, [startAnimation, endAnimation], [0, 65]);
  const yrocket = useTransform(scrollY, [startAnimation, endAnimation], [0, -350]);
  const ycloudmiddle = useTransform(scrollY,
    [startAnimation, endAnimation], [0, -360]);
  const ycloudfront = useTransform(scrollY,
    [startAnimation, endAnimation], [0, -290]);
  const scalecloudfront = useTransform(scrollY,
    [startAnimation, endAnimation], [1, 1.1]);

  return (
    <CustomerSectionBackground style={{ overflow: 'hidden', position: 'relative' }} ref={sectionRef}>
      <AnimatedRocketDiv style={{ y: yrocket, x: xrocket }} >
        <AnimatedGatsbyImage image={getImage(backSourcesThree)!} alt='rocket' />
      </AnimatedRocketDiv>
      <AnimatedCloudMiddleDiv style={{ y: ycloudmiddle }}>
        <AnimatedGatsbyImage image={getImage(middleSourcesThree)!} alt='cloud middle' />
      </AnimatedCloudMiddleDiv>
      <AnimatedCloudFrontDiv style={{ y: ycloudfront, scale: scalecloudfront }} >
        <AnimatedGatsbyImage image={getImage(frontSourcesThree)!} alt='cloud front' />
      </AnimatedCloudFrontDiv>
      <Container id='customer'>
        <SmallHeadline light>{subtitle}</SmallHeadline>
        <CustomerGrid>
          {content.map((customer) =>
            <Logo
              key={customer.title}
              src={customer.logoImage.file.url}
              alt={customer.title}
              loading='lazy'
            />)}
        </CustomerGrid>
      </Container>
    </CustomerSectionBackground>
  );
};

export default CustomerSection;
