import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { toast, ToastContainer } from 'react-toastify';
import usePlausible from '../../hooks/use-plausible';
import {
  ContactSectionHeadline,
  ContactHeadline,
  ContactName,
  ContactPosition,
} from '../../components/headlines/index';
import ContactForm from '../contact-form/contact-form';
import { sendMail } from '../../helpers/send-mail';
import toastStyle from '../../styles/toast-styles';
import { Button, ButtonSolid } from './contact-buttons';
import {
  ButtonIcon,
  ContactPerson,
  ButtonsContainer,
  ContactHeadlineContainer,
  ContactContainer,
  ContactImageContainer,
  ContactContentContainer,
  ContactTextContainer,
  TextContainer,
  BackgroundContactContainer,
} from './components';

type QueryData = {
  contactPerson: IGatsbyImageData,
  allContentfulSection: {
    nodes: {
      title: string,
      subtitle: string,
      image: {
        gatsbyImageData: IGatsbyImageData,
      },
      content: {
        title: string,
        subtitle: string,
        content: {
          content: string,
        },
      }[],
      buttons: {
        title: string,
        link: string,
        image: {
          title: string,
          file: {
            url: string,
          },
        },
      }[],
    }[],
  },
};

const Contact: React.FC = () => {
  const { plausible } = usePlausible();

  const { contactPerson, allContentfulSection }: QueryData = useStaticQuery(
    graphql`
      query {
        contactPerson: file(relativePath: { eq: "andreas-kottre-arme-verschraenkt.png" }) {
          childImageSharp {
            gatsbyImageData(width: 500, quality: 100, placeholder: BLURRED, formats: WEBP)
          }
        }
        allContentfulSection(filter: { title: { eq: "Noch Fragen?" } }) {
          nodes {
            title
            subtitle
            content {
              ... on ContentfulContact {
                title
                subtitle
                content {
                  content
                }
              }
            }
            buttons {
              title
              link
              image {
                title
                file {
                  url
                }
              }
            }
          }
        }
      }
    `,
  );

  const [openContactForm, setOpenContactForm] = useState<boolean>(false);

  const {
    nodes: [{ title, subtitle, buttons, content }],
  } = allContentfulSection;

  const phoneUrl = `https:${buttons[0].image.file.url}`;
  const mailUrl = `https:${buttons[1].image.file.url}`;

  const changeBodyOverflow = (hidden) => {
    document.body.style.overflow = hidden ? 'hidden' : 'unset';
  };

  // eslint-disable-next-line max-len
  async function handleSendMail(
    subject: string,
    mailContent: string,
    mailFrom: string,
    senderName: string,
    mailTo: string): Promise<void> {
    const id = toast.loading('Deine Anfrage wird bearbeitet', toastStyle.loading);
    try {
      await sendMail(subject, mailContent, mailFrom, senderName, mailTo);
      toast.dismiss(id);
      toast.success('Deine Nachricht ist bei uns angekommen und wird schnellstmöglich bearbeitet!', toastStyle.success);
      setOpenContactForm(false);
    } catch (error) {
      toast.dismiss(id);
      toast.error('Leider konnte die Nachricht nicht versendet werden. Kontaktiere uns gerne direkt!', toastStyle.error);
      console.error(error);
    }
  }

  return (
    <>
      {openContactForm && (
        <ContactForm
          formParams={content[1]}
          contactParams={content[0]}
          mail={buttons[1].title}
          phone={buttons[0].title}
          onClose={() => {
            setOpenContactForm(false);
            changeBodyOverflow(false);
          }}
          onSendMail={async (
            subject: string,
            mailContent: string,
            mailFrom: string,
            senderName: string,
            mailTo: string) => handleSendMail(subject, mailContent, mailFrom, senderName, mailTo)}
        />
      )}
      <BackgroundContactContainer>
        <ToastContainer />
        <ContactContainer>
          <ContactHeadlineContainer mobile>
            <ContactHeadline>{title}</ContactHeadline>
            <ContactSectionHeadline>{subtitle}</ContactSectionHeadline>
          </ContactHeadlineContainer>
          <ContactImageContainer>
            <ContactPerson image={getImage(contactPerson)!} alt='andreas-kottre-arme-verschraenkt' />
          </ContactImageContainer>
          <ContactContentContainer>
            <ContactHeadlineContainer>
              <ContactHeadline>{title}</ContactHeadline>
              <ContactSectionHeadline>{subtitle}</ContactSectionHeadline>
            </ContactHeadlineContainer>
            <ContactTextContainer>
              <Button
                primary
                width='100%'
                onClick={() => {
                  setOpenContactForm(true);
                  changeBodyOverflow(true);
                }}
              >
                Zum Kontaktformular
              </Button>
              <TextContainer>
                <ContactName dark>{content[0].subtitle}</ContactName>
                <ContactPosition>{content[0].content.content}</ContactPosition>
              </TextContainer>
              <ButtonsContainer>
                <ButtonSolid
                  type='button'
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    window.location.href = `mailto:${buttons[1].title}`;
                    plausible('kontakt-s1-button-anrufen');
                  }}
                >
                  <ButtonIcon src={mailUrl} alt={buttons[1].title} />
                  {buttons[1].link}
                </ButtonSolid>
                <ButtonSolid
                  type='button'
                  fontSize='12px'
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    window.location.href = `tel:${buttons[0].title}`;
                    plausible('kontakt-s1-button-anrufen');
                  }}
                >
                  <ButtonIcon src={phoneUrl} alt={buttons[0].title} />
                  {buttons[0].link}
                </ButtonSolid>
              </ButtonsContainer>
            </ContactTextContainer>
          </ContactContentContainer>
        </ContactContainer>
      </BackgroundContactContainer>
    </>
  );
};

export default Contact;
