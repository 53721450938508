import React from 'react';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Button } from '../buttons';
import { Photo, TeamName, MemberContainer, Tag, TagContainer, MemberQuote, MaskShadow } from './components';

type TeamMemberProps = {
  image: IGatsbyImageData,
  name: string,
  tags: string[],
  quote: string,
  showButton?: boolean,
  buttonHandler?: () => void,
};

const TeamMember: React.FC<TeamMemberProps> = (
  { image, name, tags, quote, showButton, buttonHandler },
) => (
  <MemberContainer>
    <MaskShadow>
      <Photo image={image} alt={name} />
    </MaskShadow>
    <TeamName>{name}</TeamName>
    {
      showButton ?
        <Button
          type='button'
          onClick={buttonHandler}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          width={330}
        >
          {quote}
        </Button>
        : <MemberQuote>{quote}</MemberQuote>
    }
    <TagContainer>{tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}</TagContainer>
  </MemberContainer>
);

TeamMember.defaultProps = {
  buttonHandler: () => {},
  showButton: false,
};
export default TeamMember;
