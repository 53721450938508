import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import breakpoints from '../../styles/breakpoints';

const BlogCardContainer = styled.div`
  display: flex;
  ${breakpoints.xs`
    width: 300px;
    min-height: 700px;
    flex-direction: column;
  `}
  ${breakpoints.md`
    width: 500px;
    min-height: 650px;
    flex-direction: column;
  `}
  ${breakpoints.lg`
    width: 60%;
    min-width: 950px;
    min-height: unset;
    height: 420px;
    flex-direction: row;
    background-image: linear-gradient(243deg, #fdfdfd 100%, rgba(250, 250, 250, 0.3) 0%);
    box-shadow: 0 2px 10px 1px rgba(57, 116, 128, 0.3);

    :nth-of-type(even) {
      flex-direction: row-reverse;
      text-align: right;
    }
  `}
  border-radius: 10px;
  margin-bottom: 65px;
  overflow: hidden;
`;

const BlogPostImage = styled(GatsbyImage) < { image: IGatsbyImageData } >`
  ${breakpoints.xs`
    width: 300px;
    height: 300px;
    border-radius: 10px;
  `}
  ${breakpoints.md`
    width: 500px;
    height: 300px;
    border-radius: 10px;
  `}
  ${breakpoints.lg`
    width: 100%;
    height: 100%;
    border-radius: unset;
  `}
`;

const BlogCardContent = styled.div`
  ${breakpoints.xs`
    width: 300px;
    height: auto;
  `}
  ${breakpoints.md`
    width: 500px;
    height: 300px;
  `}
  ${breakpoints.lg`
    width: 100%;
    height: 100%;
    background: rgba(230, 255, 255, 0.3);
  `}
`;

const ContentWrapper = styled.div < { isOdd: boolean } >`
  ${breakpoints.lg`
    display: flex;
    height: 100%;
    flex-flow: column;
    justify-content: space-evenly;
    padding: 0 45px;
    ${({ isOdd }) => (isOdd ? 'align-items: flex-start' : 'align-items: flex-end')}
  `}
`;

const SubtitleText = styled.p`
  font-family: ${({ theme }) => theme.typography.akkurat};
  font-size: 10px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.butterscotch.main};
  text-transform: uppercase;
  ${breakpoints.xs`
    margin-top: 14px;
  `}
`;

const BlogCardTitleText = styled.h2`
  font-family: ${({ theme }) => theme.typography.mosk.bold};
  color: ${({ theme }) => theme.color.basic.black};
  ${breakpoints.xs`
    font-size: 30px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
  `}
  ${breakpoints.lg`
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
  `}

`;

const BlogCardExcerpt = styled.p`
  margin-top: 15px;
  font-family: ${({ theme }) => theme.typography.akkurat};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.color.basic.black};
  ${breakpoints.lg`
    margin-bottom: 15px;
  `}
`;

const TextWrapper = styled.div`
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    ${breakpoints.xs`
      height: 80px;
      background: linear-gradient(rgba(212, 235, 235, 0) 0%, rgba(212, 235, 235, 1) 80%);
    `}
    ${breakpoints.lg`
      height: 60px;
      background: linear-gradient(rgba(250,250,250,0) 0%, rgba(247, 255, 255, 1) 80%);
    `}
    ${breakpoints.xl`
      height: 60px;
      background: linear-gradient(rgba(250,250,250,0) 0%, rgba(247, 255, 255, 1) 80%);
    `}
  }
  overflow: hidden;
  position: relative;

  ${breakpoints.xs`
    max-height: 350px;
  `}
  ${breakpoints.lg`
    max-height: 310px;
  `}

`;

export {
  BlogCardContainer,
  BlogPostImage,
  BlogCardContent,
  SubtitleText,
  BlogCardTitleText,
  BlogCardExcerpt,
  ContentWrapper,
  TextWrapper,
};
