import React from 'react';
import { ContentfulRichTextGatsbyReference, renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { useStaticQuery, graphql } from 'gatsby';
import { BackgroundSilver, BackgroundDarken } from '../../components/components';
import { PageHeadline, SmallHeadline } from '../../components/headlines/index';
import { ContentContainer, Container } from './components';

type QueryData = {
  allContentfulSection: {
    nodes: {
      title: string,
      subtitle: string,
      content: {
        informations: RenderRichTextData<ContentfulRichTextGatsbyReference>,
      }[],
    }[],
  },
};

const Imprint: React.FC = () => {
  const { allContentfulSection }: QueryData = useStaticQuery(graphql`
    query {
      allContentfulSection(filter: {title: {eq: "Impressum"}}) {
        nodes {
          title
          subtitle
          content {
            ... on ContentfulPrivacy {
              informations {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const options = {
    renderText: (text) =>
      text.split('\\n').flatMap((splitedText: RenderRichTextData<ContentfulRichTextGatsbyReference>, i: number) => [i > 0 && <br />, splitedText]),
  };

  const { nodes: [{ title, subtitle, content: [{ informations }] }] } = allContentfulSection;

  return (
    <BackgroundSilver>
      <BackgroundDarken>
        <Container>
          <div>
            <PageHeadline>{title}</PageHeadline>
            <SmallHeadline>
              {subtitle}
            </SmallHeadline>
          </div>
          <ContentContainer>
            {renderRichText(informations, options)}
          </ContentContainer>
        </Container>
      </BackgroundDarken>
    </BackgroundSilver>
  );
};

export default Imprint;
