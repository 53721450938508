import React from 'react';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { ToastContainer, toast } from 'react-toastify';
import iconPerson from '../../images/icon-person.svg';
import iconPhone from '../../images/icon-phone.svg';
import iconMail from '../../images/icon-mail.svg';
import iconHouse from '../../images/icon-house.svg';
import iconChatBubble from '../../images/icon-chat-bubble.svg';
import { getEnvVariables } from '../../helpers/get-env-variables';
import { fillContactTemplate } from '../../helpers/send-mail';
import toastStyle from '../../styles/toast-styles';
import { PostCardContainer, ContactFormContainer, ContactFormInputContainer, TextAreaContainer, TextArea, TextAreaIcon, ContactFormLeft, ContactFormRight, ContactFormStamp, ContactFormStampImage, ScrollFiller } from './components';
import CustomCheckbox from './checkbox';
import { ContactFormButton, MobileCloseButton } from './contact-form-buttons';
import { ContactFormHeadline, ContactFormPartnerHeadline, ContactFormSubHeadline } from './contact-form-typography';
import 'react-toastify/dist/ReactToastify.css';
import ContactInformation from './contact-information';
import InputField from './input-field';

type QueryData = {
  contactPerson: IGatsbyImageData,
  saturnIcon: IGatsbyImageData,
};
type ContactFormProps = {
  formParams: {
    title: string,
    subtitle: string,
    content: {
      content: string,
    },
  },
  contactParams: {
    title: string,
    subtitle: string,
    content: {
      content: string,
    },
  },
  mail: string,
  phone: string,
  onClose: () => void,
  onSendMail: (
    subject: string,
    content: string,
    mailFrom: string,
    senderName: string,
    mailTo: string) => void,
};
// eslint-disable-next-line padded-blocks
const ContactForm: React.FC<ContactFormProps> = ({
  formParams, contactParams, mail, phone, onClose, onSendMail,
}) => {
  const [name, setName] = React.useState<string | undefined>();
  const [company, setCompany] = React.useState<string | undefined>();
  const [email, setEmail] = React.useState<string | undefined>();
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>();
  const [message, setMessage] = React.useState<string | undefined>();
  const [checkboxChecked, setCheckboxChecked] = React.useState<boolean>(false);
  const failed = () => toast.error('Leider konnte die Nachricht nicht versendet werden. Kontaktiere uns gerne direkt!', toastStyle.error);

  const { contactPerson, saturnIcon }: QueryData = useStaticQuery(
    graphql`
      query {
        contactPerson: file(relativePath: { eq: "portrait-andreas-kottre.png" }) {
          childImageSharp {
            gatsbyImageData(width: 50, quality: 100, placeholder: BLURRED, formats: WEBP)
          }
        }
        saturnIcon: file(relativePath: { eq: "saturn-icon.png" }) {
          childImageSharp {
            gatsbyImageData(width: 50, quality: 50, placeholder: BLURRED, formats: WEBP)
          }
        }
      }
    `,
  );

  const contentArray = formParams.content.content.split('\n');
  const saturnImage = getImage(saturnIcon)!;
  const contactImage = getImage(contactPerson)!;

  function handleSendMail(): void {
    const envVariables = getEnvVariables();
    if (!name || !email || !message || !envVariables.GATSBY_SOLUTIONS_MAIL) {
      failed();
      return;
    }
    const content = fillContactTemplate(name, email, message, phoneNumber, company);
    onSendMail('Jemand versucht mit mit euch Kontakt aufzunehmen', content, email, name, envVariables.GATSBY_SOLUTIONS_MAIL);
  }

  return (
    <PostCardContainer>
      <ToastContainer />
      <ContactFormContainer>
        <ContactFormHeadline>{formParams.title}</ContactFormHeadline>
        <ContactFormStamp>
          <ContactFormStampImage image={saturnImage} alt='post stamp' />
        </ContactFormStamp>
        <ContactFormLeft>
          <ContactFormSubHeadline>{formParams.subtitle}</ContactFormSubHeadline>
          <ContactFormInputContainer>
            <InputField
              placeholder={contentArray[0]}
              onChange={(e) => setName(e.target.value)}
              value={name}
              icon={iconPerson}
            />
            <InputField
              placeholder={contentArray[1]}
              onChange={(e) => setCompany(e.target.value)}
              value={company}
              icon={iconHouse}
            />
            <InputField
              placeholder={contentArray[2]}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              icon={iconMail}
            />
            <InputField
              placeholder={contentArray[3]}
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
              icon={iconPhone}
            />
            <TextAreaContainer>
              <TextAreaIcon src={iconChatBubble} alt='icon chat bubble' />
              <TextArea
                placeholder={contentArray[4]}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
            </TextAreaContainer>
            <CustomCheckbox
              onChange={(e) => setCheckboxChecked(e.target.checked)}
              info={contentArray[5]}
            />
          </ContactFormInputContainer>
          <ContactFormButton primary mobile width='100%'>{contentArray[7]}</ContactFormButton>
        </ContactFormLeft>
        <ContactFormRight>
          <ContactFormPartnerHeadline>{contentArray[6]}</ContactFormPartnerHeadline>
          <ContactInformation
            image={contactImage}
            name={contactParams.subtitle}
            jobPosition={contactParams.content.content}
            mail={mail}
            phone={phone}
          />
          <ContactFormButton
            disabled={!email || !name || !message || !checkboxChecked}
            primary
            width='100%'
            onClick={() => handleSendMail()}
          >
            {contentArray[7]}
          </ContactFormButton>
          <ContactFormButton width='100%' onClick={() => onClose()}>{contentArray[8]}</ContactFormButton>
          <MobileCloseButton onClick={() => onClose()} />
        </ContactFormRight>
        <ScrollFiller />
      </ContactFormContainer>
    </PostCardContainer>
  );
};

export default ContactForm;
