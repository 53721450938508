import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const ContactFormHeadline = styled.h2`
  ${breakpoints.xs`
    margin-top: 50px;
  `}
  ${breakpoints.md`
    margin-top: 0;
  `}
  width: 100%;
  font-size: 14px;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  font-weight: lighter;
  color: ${({ theme }) => theme.color.basic.black};
  margin-bottom: 20px;
`;

const ContactFormSubHeadline = styled.h1`
  ${breakpoints.xs`
    font-size: 18px;
  `}
  ${breakpoints.md`
    font-size: 26px;
  `}
  font-size: 26px;
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  font-weight: 800;
  line-height: normal;
  color: ${({ theme }) => theme.color.basic.black};
  margin-bottom: 20px;
`;

const ContactFormPartnerHeadline = styled.h3`
  font-size: 14px;
  font-family: ${({ theme }) => theme.typography.mosk.medium}, sans-serif;
  font-weight: lighter;
  color: ${({ theme }) => theme.color.hamam.shade};
  position: relative;
  padding-bottom: 10px;
  &:after {
    content: '';
    width: 40px;
    height: 2px;
    background: ${({ theme }) => theme.color.hamam.shade};
    position: absolute;
    bottom: -2px;
    left: 0;
  }
`;

const ContactPersonName = styled.h2`
  width: inherit;
  font-size: 20px;
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme }) => theme.color.basic.black};
`;

const ContactPersonPosition = styled.p`
  width: inherit;
  font-size: 14px;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.black};
  bottom: 0;
  letter-spacing: .5px;
`;

const ContactData = styled.a`
  width: 100%;
  font-size: 14px;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: rgba(50, 50, 50, 0.5);
  letter-spacing: .5px;
  text-decoration: none;
  margin-bottom: 10px;
  text-transform: lowercase;

  &:hover {
    cursor: pointer;
  }
`;

export {
  ContactFormHeadline,
  ContactFormSubHeadline,
  ContactFormPartnerHeadline,
  ContactPersonName,
  ContactPersonPosition,
  ContactData,
};
