
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  ${breakpoints.xs`
    padding: 0 11%;
  `}
  ${breakpoints.lg`
    padding: 0 17%;
  `}
  ${breakpoints.xl`
    padding: 0 20%;
  `}
`;
const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 150px;
  justify-content: center;
  align-items: center;
`;

const GridContainer = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  margin-top: 100px;
  grid-template-columns: 1fr 1fr;
  padding: 0 29%;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 50px;
  margin-bottom: 130px;
  ${breakpoints.xs`
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    padding: 0;
  `}
  ${breakpoints.lg`
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;

  `}
  ${breakpoints.xl`
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;

  `}
`;
const ContentBox = styled.div < { key: string } >`
  align-items: center;
  display: flex;
  flex-direction: column;
  place-self: center;
`;
const BottomContentBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  place-self: center;
`;
const Icon = styled(GatsbyImage)`
  height: 100%;
  width: 100px;
  ${breakpoints.xs`
  width: 120px;
`}
  ${breakpoints.lg`
  width: 140px;
`}
`;
export { Container, ContentBox, BottomContentBox, Icon, GridContainer, HeadlineContainer };
