import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import backgroundPostStamp from '../../images/background-post-stamp.svg';
import breakpoints from '../../styles/breakpoints';

const PostCardContainer = styled.div`
  ${breakpoints.xs`
    position: absolute;
  `}
  ${breakpoints.md`
    position: fixed;
  `}
  height: 100vh;
  width: 100vw;
  background-color: rgba(20, 20, 20, 0.25);
  z-index: 1;
`;

const ContactFormContainer = styled.div`
  ${breakpoints.xs`
    display: block;
    margin: 10vh 0;
    transform: translate(0);
    top: 0;
    left: 0;
    padding: 15px;
    overflow: scroll;
    height: 90vh;
  `}
  ${breakpoints.md`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 700px;
    padding: 20px;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: unset;
    height: auto;
  `}
  ${breakpoints.lg`
    width: 750px;
  `}
  ${breakpoints.xl`
    width: 1000px;
    padding: 30px;
  `}
  position: fixed;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='1.5' numOctaves='3' stitchTiles='stitch'/%3E%3CfeColorMatrix type='saturate' values='0'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' opacity='0.4'/%3E%3C/svg%3E");
  background-color: ${({ theme }) => theme.color.basic.silver};
  background-size: cover;
  border-radius: 10px;
`;

const ContactFormLeft = styled.div`
  ${breakpoints.xs`
    padding-right: 0;
    width: 100%;
  `}
  ${breakpoints.md`
    padding-right: 20px;
    width: 60%;
    border-right: 2px dashed rgba(50, 50, 50, 0.25);
  `}
  ${breakpoints.xl`
    padding-right: 30px;
  `}
`;

const ContactFormRight = styled.div`
  ${breakpoints.xs`
    padding-left: 0;
    width: 100%;
    margin-top: 40px;
  `}
  ${breakpoints.md`
    padding-left: 20px;
    width: 33%;
    margin-top: 0;
  `}
  ${breakpoints.xl`
    padding-left: 30px;
  `}
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
`;

const ScrollFiller = styled.div`
  ${breakpoints.xs`
    height: 10vh;
  `}
  ${breakpoints.md`
    height: 0;
  `}
`;

const ContactDataContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const ContactFormInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 5%;
  row-gap: 20px;
  padding-top: 20px;
`;

const InputFieldContainer = styled.div`
  ${breakpoints.xs`
    width: 100%;
  `}
  ${breakpoints.md`
    width: 47.5%;
  `}
  height: 40px;
`;

const TextAreaContainer = styled.div`
  width: 100%;
  height: 150px;
`;

const InputFieldIcon = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const TextAreaIcon = styled.img`
  width: 20px;
  margin: 10px 10px 0 0;
`;

type InputProps = {
  hasError?: boolean,
};

const Input = styled.input<InputProps>`
  width: calc(100% - 54px);
  border: 2px solid ${({ hasError, theme }) => (hasError ? theme.color.butterscotch.main : 'transparent')};
  caret-color: ${({ theme }) => theme.color.hamam.tint};
  border-radius: 8px;
  height: inherit;
  padding: 0 10px;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  font-size: 14px;

  &:hover, :focus {
    border: 2px solid ${({ theme }) => theme.color.hamam.tint};
  }
`;

const TextArea = styled.textarea`
  width: calc(100% - 54px);
  border: 2px solid transparent;
  caret-color: ${({ theme }) => theme.color.hamam.tint};
  border-radius: 8px;
  height: inherit;
  padding: 10px;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  font-size: 14px;

  &:hover, :focus {
    border: 2px solid ${({ theme }) => theme.color.hamam.tint};
  }
`;

const ContactPersonContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ContactInformationWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const ContactInfoImage = styled.img`
  width: 14px;
  margin-right: 5px;
`;

const ContactFormStamp = styled.div`
  ${breakpoints.xs`
    display: none;
  `}
  ${breakpoints.md`
    display: block;
    top: 20px;
    right: 20px;
  `}
  ${breakpoints.xl`
    top: 30px;
    right: 30px;
  `}
  position: absolute;
  padding: 10px;
  width: 60px;
  height: 60px;
  top: 0;
  right: 0;
  background-image: url(${backgroundPostStamp});
  filter: drop-shadow(0px 0px 5px rgba(50, 50, 50, 0.25));
`;

const ContactFormStampImage = styled(GatsbyImage)`
  width: 100%;
`;

export {
  PostCardContainer,
  ContactFormContainer,
  ContactFormLeft,
  ContactFormRight,
  ScrollFiller,
  ContactDataContainer,

  ContactFormInputContainer,
  InputFieldContainer,
  TextAreaContainer,
  InputFieldIcon,
  TextAreaIcon,
  Input,
  TextArea,

  ContactPersonContainer,
  ContactInformationWrapper,
  ContactInfoImage,

  ContactFormStamp,
  ContactFormStampImage,
};
