import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
`;

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 120px 0 40px;
`;
const ContentContainer = styled.div`
  position: relative;
  place-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 130px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 1200px;

  ${breakpoints.xs`
  width: 100%;
  `}
  ${breakpoints.lg`
  width: 100%;
  `}
  ${breakpoints.xl`
  width: 80%;
  `}
`;
const CardContainer = styled.div < { key: string } >`
  display: flex;
  flex-direction: column;
  ${breakpoints.lg`
    width: 450px;
    place-self: center end;
    :nth-of-type(even) {
      place-self: center start;
    }
`}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 2px 10px 1px rgba(166, 50, 69, 0.5);
  // Dunkelrot
  background-image: radial-gradient(circle at 50% 0, rgba(253, 253, 253, 0.2), rgba(250, 250, 250, 0.1) 86%);
  gap: 10px;

  ${breakpoints.xs`
    width: 75%;
    min-height: 300px;
    align-self: center;
    padding: 50px 15px 0;
  `}
  ${breakpoints.sm`
    min-height: auto;
  `}
  ${breakpoints.lg`
    width: 360px;
    min-height: 340px;
    padding: 65px 30px 0;
    place-self: center end;
    :nth-of-type(even) {
      place-self: center start;
    }
  `}
`;

const CardImage = styled(GatsbyImage)`
  height: 100%;
  width: 100px;
  position: relative;
  align-self: center;
  top: 60px;
  ${breakpoints.xs`
  width: 120px;
`}
  ${breakpoints.lg`
  width: 140px;
`}
`;

const CardHeadline = styled.div`
  font-family: ${({ theme }) => theme.typography.mosk.bold};
  font-weight: 800;
  color: ${({ theme }) => theme.color.basic.white};
  font-size: 32px;
  line-height: 43px;
  letter-spacing: 1.5px;
  margin-bottom: 25px;

  ${breakpoints.xs`
    font-size: 24px;
  `}
  ${breakpoints.lg`
    font-size: 32px;
  `}
`;

const CardText = styled.p`
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: center;

  ${breakpoints.xs`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.2px;
  `}
  ${breakpoints.sm`
    font-size: 16px;
  `}
  ${breakpoints.lg`
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: 0.5px;
  `}

  &:last-of-type {
    margin-bottom: 30px;
  }
`;

export {
  Container,
  CardContainer,
  HeadlineContainer,
  ContentContainer,
  TextContainer,
  CardHeadline,
  CardText,
  CardImage,
};
