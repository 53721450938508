import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  BackgroundButterscotch,
} from '../../components/components';
import { SmallHeadline } from '../../components/headlines/index';
import Technology from '../../components/technology/technology';
import { TechnologiesGrid, Container } from './components';

type queryData = {
  allContentfulSection: {
    edges: {
      node: {
        title: string,
        content: {
          title: string,
          image: {
            file: {
              url: string,
            },
          },
          description: {
            description: string,
          },
        }[],
      },
    }[],
  },
};

const TechnologiesSection: React.FC = () => {
  const {
    allContentfulSection,
  }: queryData = useStaticQuery(
    graphql`
      query {
        allContentfulSection(filter: {title: {eq: "Technologien"}}) {
          edges {
            node {
              title
              content {
                ... on ContentfulTechnologies {
                  title
                  image {
                    file {
                      url
                    }
                  }
                  description {
                    description
                  }
                }
              }
            }
          }
        }
      }
    `,
  );

  return (
    <BackgroundButterscotch>
      <Container id='technologies'>
        <SmallHeadline light>{allContentfulSection.edges[0].node.title}</SmallHeadline>
        <TechnologiesGrid>
          {allContentfulSection.edges[0].node.content.map((content) =>
            <Technology
              key={content.title}
              src={content.image.file.url}
              title={content.title}
              description={content.description.description}
            />,
          )}
        </TechnologiesGrid>
      </Container>
    </BackgroundButterscotch>
  );
};

export default TechnologiesSection;
