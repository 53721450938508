import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const Container = styled.div < { id: string } >`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  place-items: center;
  padding: 0 0 175px;
`;

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
`;

const TeamMemberContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  place-items: center;
  justify-content: space-evenly;
  align-items: baseline;
  ${breakpoints.xl`
    width: 1200px;
  `}
`;

export {
  Container,
  HeadlineContainer,
  TeamMemberContainer,
};
