import styled from 'styled-components';

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  display: grid;
  margin-top: -100vh;
  position: relative;
  bottom: 0;
  left: 0;
  min-height: 100vh;
  place-items: start center;
  ${breakpoints.xs`
    grid-template-rows: 175px 1fr 50px;
  `}
  ${breakpoints.lg`
    grid-template-rows: 200px 1fr 100px;
  `}
  ${breakpoints.xl`
    grid-template-rows: 250px 1fr 100px;
  `}
`;

const ProcessContainer = styled.div`
  display: grid;
  place-items: center;
  ${breakpoints.xs`
    grid-template-columns: 1fr;
    grid-gap: 35px;
  `}
  ${breakpoints.sm`
    grid-template-columns: 1fr;
    grid-gap: 75px;
  `}
  ${breakpoints.lg`
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px;
  `}
`;

const SubLineContainer = styled.div`
  display: grid;
  ${breakpoints.xs`
    grid-template-columns: 1fr;
    grid-gap: 35px;
  `}
  ${breakpoints.sm`
    grid-template-columns: 1fr;
    grid-gap: 75px;
  `}
  ${breakpoints.lg`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 100px;
    grid-column: 1/4;
  `}
  place-items: center;
`;

const TextBox = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 5px;
  ${breakpoints.xs`
    height: 50px;
    width: 290px;
  `}
  ${breakpoints.lg`
    height: 200px;
    width: 200px;
  `}
  ${breakpoints.xl`
    height: 200px;
    width: 360px;
  `}
`;

const StepHeadline = styled.h3`
  ${breakpoints.xs`
    font-size: 28px;
    line-height: 1.19;
    letter-spacing: 0.5px;
  `}
  ${breakpoints.lg`
    font-size: 34px;
    line-height: 1.61;
    letter-spacing: 2px;
    place-self: end center;
  `}
  ${breakpoints.xl`
    font-size: 48px;
    line-height: 1.61;
    letter-spacing: 2px;
    place-self: center;
  `}
  text-align: center;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme }) => theme.color.basic.black};
`;
const StepText = styled.p`
  ${breakpoints.xs`
    display: none;
  `}
  ${breakpoints.lg`
    display: block;
    font-size: 12px;
    line-height: 1.56;
    letter-spacing: 0.5px;
  `}
  ${breakpoints.xl`
    display: block;
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: 0.5px;
  `}
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.black};
`;

const ProcessImage = styled(GatsbyImage)<{image: IGatsbyImageData}>`
  width: 100%;
  height: 100vh;
`;

export {
  Container,
  ProcessContainer,
  SubLineContainer,
  TextBox,
  StepHeadline,
  StepText,
  ProcessImage,
};
