import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// import usePlausible from '../../hooks/use-plausible';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { BackgroundSilver } from '../components';
import { ContentContainer } from '../dev-team-hero-shot/components';
import { ButtonContainer, Container, HighlightText, ImageContainer, Subtitle, TextContainer, TitleImage } from './components';
import ContactButton from './buttons';

type ButtonInformation = {
  title: string,
  link: string,
};

type QueryData = {
  'allContentfulSection': {
    'nodes': [
      {
        'buttons': ButtonInformation [],
        'subtitle': string,
        content: {
          description: RenderRichTextData<ContentfulRichTextGatsbyReference>,
          button: string,
        }[],
        image: [
          {
            gatsbyImageData: IGatsbyImageData,
          }
        ],
      }
    ],
  },
};

const DevTeamsIntroduction: React.FC = () => {
  // const { plausible } = usePlausible();
  const { allContentfulSection }: QueryData = useStaticQuery(graphql`
    query {
      allContentfulSection(filter: {contentful_id: {eq: "1isRc2XZgSqOKBOp0J6auk"}}) {
        nodes {
          buttons {
            title
            link
          }
          subtitle
          content {
            ... on ContentfulApplicationText {
              description {
                raw
              }
              button
            }
          }
          image {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const {
    nodes:
    [{ subtitle, buttons, image, content: [{ description }] }],
  } = allContentfulSection;

  return (
    <>
      <BackgroundSilver>
        <Container >
          <ContentContainer>
            <ImageContainer>
              <TitleImage image={getImage(image[0].gatsbyImageData)!} alt='image of ' />
            </ImageContainer>
            <TextContainer>
              <Subtitle>{subtitle}</Subtitle>
              <HighlightText>{renderRichText(description)}</HighlightText>
              <ButtonContainer>
                {
                  buttons.map((el) =>
                    <ContactButton
                      width='100%'
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      key={el.title}
                      primary
                      onClick={() => {
                        window.location.href = `tel:${el.link}`;
                      }}
                    >
                      {el.title}
                    </ContactButton>,
                  )
                }
              </ButtonContainer>
            </TextContainer>
          </ContentContainer>
        </Container>
      </BackgroundSilver>
    </>
  );
};

export default DevTeamsIntroduction;
