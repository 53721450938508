import React from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { useViewportScroll, useTransform } from 'framer-motion';

import { getImage, withArtDirection } from 'gatsby-plugin-image';
import AnimatedGatsbyImage from '../../components/animated-gatsby-image/animated-gatsby-image';

import { IGatsbyImageDataProps } from '../../types/image-types';
import { AnimatedDiv } from '../../components/background-image/background-image';
import { HeroHeadline } from '../../components/headlines/index';
import { HeroContainer } from '../../components/content-container/index';
import { Button } from '../../components/buttons/index';
import usePlausible from '../../hooks/use-plausible';
import {
  ButtonContainer,
  ContactButton,
  HeroSectionBackground,
} from './components';

type ValueProps = {
  handleWhatWeDoClick: VoidFunction,
};

type QueryData = {
  frontDesktop: IGatsbyImageDataProps,
  frontMobile: IGatsbyImageDataProps,
  middleDesktop: IGatsbyImageDataProps,
  middleMobile: IGatsbyImageDataProps,
  backDesktop: IGatsbyImageDataProps,
  backMobile: IGatsbyImageDataProps,
  allContentfulSection: {
    nodes: {
      title: string,
      subtitle: string,
      buttons: {
        title: string,
        link: string,
      }[],
    }[],
  },
};

const HeroSection: React.FC<ValueProps> = ({ handleWhatWeDoClick }) => {
  const { plausible } = usePlausible();

  const {
    frontDesktop,
    frontMobile,
    middleDesktop,
    middleMobile,
    backDesktop,
    backMobile,
    allContentfulSection,
  }: QueryData = useStaticQuery(
    graphql`
    query {
      frontMobile: file(relativePath: { eq: "section-one-bg-front-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100,
            width: 490,
            formats: WEBP,
            placeholder: BLURRED,
            layout: FULL_WIDTH
          )
        }
      }
      middleMobile: file(relativePath: { eq: "section-one-bg-middle-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100,
            width: 490,
            formats: WEBP,
            placeholder: BLURRED,
            layout: FULL_WIDTH
          )
        }
      }
      backMobile: file(relativePath: { eq: "section-one-bg-back-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100,
            width: 490,
            formats: WEBP,
            placeholder: BLURRED,
            layout: FULL_WIDTH
          )
        }
      }
      frontDesktop: file(relativePath: { eq: "section-one-bg-front.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100,
            width: 4160,
            formats: WEBP,
            placeholder: BLURRED,
            layout: FULL_WIDTH
          )
        }
      }
      middleDesktop: file(relativePath: { eq: "section-one-bg-middle.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100,
            width: 4160,
            formats: WEBP,
            placeholder: BLURRED,
            layout: FULL_WIDTH
          )
        }
      }
      backDesktop: file(relativePath: { eq: "section-one-bg-back.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100,
            width: 4160,
            formats: WEBP,
            placeholder: BLURRED,
            layout: FULL_WIDTH
          )
        }
      }
      allContentfulSection(filter: {title: {eq: "Hero"}}) {
        nodes {
          title
          subtitle
          buttons {
            title
            link
          }
        }
      }
    }`,
  );

  const { nodes: [{ subtitle, buttons }] } = allContentfulSection;

  const frontSources = withArtDirection(getImage(frontDesktop.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(frontMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);
  const middleSources = withArtDirection(getImage(middleDesktop.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(middleMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);
  const backSources = withArtDirection(getImage(backDesktop.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(backMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);

  const { scrollYProgress } = useViewportScroll();

  const ydesk = useTransform(scrollYProgress, [0, 0.05], [0, -90]);
  const xperson = useTransform(scrollYProgress, [0, 0.05], [0, -40]);
  const yperson = useTransform(scrollYProgress, [0, 0.05], [0, -75]);
  const yblackboard = useTransform(scrollYProgress, [0, 0.05], [-10, -60]);

  return (
    <HeroSectionBackground>
      <AnimatedDiv style={{ y: yblackboard, transition: 'all 0.7s ease-out' }}>
        <AnimatedGatsbyImage image={getImage(backSources)!} alt='image of blackboard' />
      </AnimatedDiv>
      <AnimatedDiv
        style={{ y: yperson, x: xperson, transition: 'all 0.7s ease-out' }}
      >
        <AnimatedGatsbyImage image={getImage(middleSources)!} alt='image of person' />
      </AnimatedDiv>
      <AnimatedDiv style={{ y: ydesk, transition: 'all 0.7s ease-out' }}>
        <AnimatedGatsbyImage image={getImage(frontSources)!} alt='image of desk with person' />
      </AnimatedDiv>
      <HeroContainer>
        <HeroHeadline>{subtitle}</HeroHeadline>
        <ButtonContainer>
          <Button
            type='button'
            onClick={handleWhatWeDoClick}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            {buttons[0].title}
          </Button>
          <ContactButton
            type='button'
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            outlined
            onClick={() => {
              navigate(buttons[1].link);
              plausible('start-s1-button-kontakt');
            }}
          >
            {buttons[1].title}
          </ContactButton>
        </ButtonContainer>
      </HeroContainer>
    </HeroSectionBackground>
  );
};

export default HeroSection;
