import React, { RefObject } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { withArtDirection, getImage } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import usePlausible from '../../hooks/use-plausible';

import { BackgroundSilver, BackgroundDarken } from '../../components/components';
import { IGatsbyImageDataProps } from '../../types/image-types';
import { Container, HrHeadline, HrSlogan, RocketImage, ContactButton } from './components';

type Props = {
  scrollRef:
  ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined,
};

type QueryData = {
  frontThree: IGatsbyImageDataProps,
  middleThree: IGatsbyImageDataProps,
  backThree: IGatsbyImageDataProps,
  frontThreeMobile: IGatsbyImageDataProps,
  backThreeMobile: IGatsbyImageDataProps,
  middleThreeMobile: IGatsbyImageDataProps,
  allContentfulSection: {
    nodes: {
      subtitle: string,
      content: {
        description: RenderRichTextData<ContentfulRichTextGatsbyReference>,
        button: string,
      }[],
    }[],
  },
};

const HrSection: React.FC<Props> = ({ scrollRef }) => {
  const { plausible } = usePlausible();

  const {
    frontThree,
    middleThree,
    backThree,
    frontThreeMobile,
    backThreeMobile,
    middleThreeMobile,
    allContentfulSection,
  }: QueryData = useStaticQuery(
    graphql`
        query {
          frontThree: file(relativePath: { eq: "section-three-bg-front.png" }) {
            childImageSharp {
              gatsbyImageData(
                quality: 100,
                width: 4160,
                placeholder: BLURRED,
              )
            }
          }
          middleThree: file(relativePath: { eq: "section-three-bg-middle.png" }) {
            childImageSharp {
              gatsbyImageData(
                quality: 100,
                width: 4160,
                placeholder: BLURRED,
              )
            }
          }
          backThree: file(relativePath: { eq: "section-three-bg-back.png" }) {
            childImageSharp {
              gatsbyImageData(
                quality: 100,
                width: 4160,
                placeholder: BLURRED,
              )
            }
          }
          frontThreeMobile: file(relativePath: { eq: "section-three-bg-front-mobile.png" }) {
            childImageSharp {
              gatsbyImageData(
                quality: 100,
                width: 490,
                formats: WEBP,
                placeholder: BLURRED
              )
            }
          }
          middleThreeMobile: file(relativePath: { eq: "section-three-bg-middle-mobile.png" }) {
            childImageSharp {
              gatsbyImageData(
                quality: 100,
                width: 490,
                formats: WEBP,
                placeholder: BLURRED
              )
            }
          }
          backThreeMobile: file(relativePath: { eq: "section-three-bg-back-mobile.png" }) {
            childImageSharp {
              gatsbyImageData(
                quality: 100,
                width: 490,
                formats: WEBP,
                placeholder: BLURRED
              )
            }
          }
          allContentfulSection(filter: {subtitle: {eq: "Komm mit an Board"}}) {
            nodes {
              subtitle
              content {
                ... on ContentfulApplicationText {
                  description {
                    raw
                  }
                  button
                }
              }
            }
          }
      }
    `,
  );

  const { nodes: [{ subtitle, content: [{ description, button }] }] } = allContentfulSection;

  const frontSourcesThree
  = withArtDirection(getImage(frontThree.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(frontThreeMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);
  const middleSourcesThree
  = withArtDirection(getImage(middleThree.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(middleThreeMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);
  const backSourcesThree = withArtDirection(getImage(backThree.childImageSharp.gatsbyImageData)!, [
    {
      media: `(max-width: 992px)`,
      image: getImage(backThreeMobile.childImageSharp.gatsbyImageData)!,
    },
  ]);

  const options = {
    renderText: (text: string) =>
      text.split('\\n').flatMap((splitedText: unknown, i: number) => [i > 0 && <br />, splitedText]),
  };

  return (
    <BackgroundSilver>
      <BackgroundDarken>
        <Container id='apply' ref={scrollRef}>
          <HrHeadline>{subtitle}</HrHeadline>
          <HrSlogan>
            {renderRichText(description, options)}
          </HrSlogan>
          <ContactButton
            primary
            type='button'
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              navigate('/kontakt');
              plausible('team-s4-button-kontakt');
            }}
          >
            {button}
          </ContactButton>
          <RocketImage image={getImage(backSourcesThree)!} alt='image of rocket' />
          <RocketImage image={getImage(middleSourcesThree)!} alt='image of cloud middle' />
          <RocketImage image={getImage(frontSourcesThree)!} alt='image of cloud front' />
        </Container>
      </BackgroundDarken>
    </BackgroundSilver>
  );
};

export default HrSection;
