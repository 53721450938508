
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  ${breakpoints.xs`
    padding: 0 11vw;
  `}
  ${breakpoints.lg`
    padding: 0 17vw;
  `}
  ${breakpoints.xl`
    padding: 0 20vw;
  `}
`;
const SecondContainer = styled.div`
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  padding: 0 10vw;

  ${breakpoints.lg`
    padding: 0 5vw;
  `}
`;
const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 150px;
  justify-content: center;
  align-items: center;
`;
const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  display: block;
  margin-top: 50px;

  ${breakpoints.xl`
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 5vw;
    margin-top: 0;
  `}
`;

const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  text-align: center;

  ${breakpoints.xl`
    width: 42.5vw;
    text-align: left;
  `}
`;

const GridContainer = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  margin-top: 100px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 50px;
  margin-bottom: 0px;
  ${breakpoints.xs`
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  `}
  ${breakpoints.lg`
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    margin-bottom: 130px;
  `}
  ${breakpoints.xl`
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 0px;
  `}
`;
const ContentBox = styled.div < { key: string } >`
  align-items: center;
  display: flex;
  flex-direction: column;
  place-self: center;
`;
const BottomContentBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  place-self: center;
`;
const Quote = styled.div`
  font-family: ${({ theme }) => theme.typography.mosk.medium};
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-align: left;
  margin-bottom: 25px;
`;
const Quotee = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.typography.mosk.bold};
  font-size: 28px;
  font-weight: 800;
  line-height: 43px;
  letter-spacing: 1.5px;
  text-align: center;
  ${breakpoints.xl`
    text-align: left;
  `}
`;
const Position = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.typography.mosk.medium};
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-align: center;
  margin-bottom: 25px;

  ${breakpoints.xs`
    font-size: 12px;
    line-height: 16px;
  `}
  ${breakpoints.xl`
    font-size: 24px;
    line-height: 32px;
    letter: 1.5px;
    text-align: left;
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  justify-content: center;

  ${breakpoints.xs`
    margin-top: 30px;
  `}
  ${breakpoints.md`
    margin-top: 75px;
  `}
  ${breakpoints.xl`
    width: 42.5vw;
  `}
`;
const TitleImage = styled(GatsbyImage)`
  height: inherit;
  align-self: center;
  object-fit: cover;
  border-radius: 10px;
`;

const Icon = styled(GatsbyImage)`
  height: 100%;
  width: 100px;
  ${breakpoints.xs`
  width: 120px;
`}
  ${breakpoints.lg`
  width: 140px;
`}
`;
export {
  Container,
  SecondContainer,
  HeadlineContainer,
  ContentContainer,
  TextContainer,
  GridContainer,
  ContentBox,
  BottomContentBox,
  Quote,
  Quotee,
  Position,
  ImageContainer,
  TitleImage,
  Icon,
};
