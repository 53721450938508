/* eslint-disable import/no-extraneous-dependencies */
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styled from 'styled-components';

type TooltipProps = {
  allowHTML: boolean,
  content: JSX.Element,
};

const Tooltip = styled(Tippy)<TooltipProps>`
  background: ${({ theme }) => theme.color.basic.silver};

  &[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: ${({ theme }) => theme.color.basic.silver};
  }

  &[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: ${({ theme }) => theme.color.basic.silver};
  }

`;

const TooltipText = styled.p`
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  padding: 10px;
  color: ${({ theme }) => theme.color.basic.black};

  ::selection {
    background: ${({ theme }) => theme.color.butterscotch.tint};
    text-shadow: none;
  }
`;

export {
  Tooltip,
  TooltipText,
};
