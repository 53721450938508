import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { TargetAndTransition, VariantLabels } from 'framer-motion';
import breakpoints from '../../styles/breakpoints';
import { ButtonOutlined } from '../../components/buttons/index';
import { BackgroundButterscotch } from '../../components/components';

const ButtonContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  ${breakpoints.xs`
    place-self: start center;
  `}
  ${breakpoints.lg`
    grid-template-columns: 260px 260px;
    place-self: start;
  `}
`;

type ContactButtonProps = {
  type: string,
  whileHover: VariantLabels | TargetAndTransition,
  whileTap: VariantLabels | TargetAndTransition,
  outlined: boolean,
  onClick: VoidFunction,
};

const ContactButton = styled(ButtonOutlined)<ContactButtonProps>`
  ${breakpoints.xs`
    display: none;
  `}
  ${breakpoints.lg`
    grid-template-columns: 260px 260px;
    place-self: start;
    display: block;
  `}
`;

const HeroSectionBackground = styled(BackgroundButterscotch)`
  position: relative;
`;

const Image = styled(GatsbyImage)`

`;

export {
  ButtonContainer,
  ContactButton,
  HeroSectionBackground,
  Image,
};
