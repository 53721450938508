/* eslint-disable max-lines */
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.white};
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 10vw;

  ${breakpoints.lg`
    padding: 0 5vw;
  `}
`;

const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  display: block;

  ${breakpoints.lg`
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 5vw;
  `}
`;

const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;

  ${breakpoints.lg`
    width: 42.5vw;
    text-align: left;
    align-items: flex-start;
  `}
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  ${breakpoints.lg`
    width: 42.5vw;
  `}
`;
const TitleImage = styled(GatsbyImage)`
  height: inherit;
  align-self: center;
  object-fit: cover;
  border-radius: 10px;
`;

const Headline = styled.div`
  font-family: ${({ theme }) => theme.typography.mosk.medium};
  font-weight: 600;
  margin-bottom: 25px;
  font-size: 32px;
  line-height: 42px;

  ${breakpoints.xl`
    font-size: 48px;
    line-height: 66px;
  `}
`;
const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.typography.mosk.medium};
  font-weight: 500;
  letter-spacing: 1.5px;
  margin-bottom: 25px;
  text-align: center;

  ${breakpoints.lg`
    font-size: 18px;
    line-height: 24px;
    text-align: left;
  `}
  ${breakpoints.xl`
    font-size: 24px;
    line-height: 32px;
    letter: 1.5px;
  `}

`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 25px;

  ${breakpoints.lg`
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-bottom: 0;
  `}
`;

export {
  Container,
  ContentContainer,
  ButtonContainer,
  TextContainer,
  ImageContainer,
  TitleImage,
  Headline,
  Subtitle,
};
