import styled, { keyframes } from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const HeroContainer = styled.div`
  display: grid;
  position: relative;
  bottom: 0;
  left: 0;
  height: 100vh;
  ${breakpoints.xs`
    grid-template-rows: 1fr 100px;
    place-items: start center;
  `}
  ${breakpoints.lg`
    grid-template-rows: 1fr 150px;
    grid-template-columns: 1fr;
    padding: 0 0 0 60px;
    place-items: end start;
  `}
`;

const PitchContainer = styled.div`
  display: grid;
  position: relative;
  bottom: 0;
  left: 0;
  height: 100vh;
  ${breakpoints.xs`
    grid-template-rows: 1fr 200px;
    place-items: start center;
  `}
  ${breakpoints.sm`
    grid-template-rows: 1fr 130px;
  `}
  ${breakpoints.md`
    grid-template-rows: 1fr 200px;
  `}
  ${breakpoints.lg`
    grid-template-rows: 1fr 150px;
    grid-template-columns: 1fr;
    padding: 0 60px 0 0;
    place-items: end end;
  `}
`;

const ProjectButtonContainer = styled.div`
  justify-items: end;
  z-index: 1;

  ${breakpoints.xs`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 40px;
    gap: 60px;
    `}
  ${breakpoints.md`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    margin-top: unset;
    gap: unset;
    `}
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const ProjectContainer = styled.div`
  display: grid;
  padding: 10vh 10vw 20vh;
  animation: ${fadeIn}  linear 0.4s;
  text-align: center;

  ${breakpoints.xs`
    grid-template-rows: 120px 1fr;
    padding: 10vh 5vw 35vh;
  `}
  ${breakpoints.md`
    grid-template-rows: 200px 400px auto;
    padding: 10vh 5vw 20vh;
  `}
  ${breakpoints.lg`
    grid-template-rows: 140px 400px 1fr;
    padding: 10vh 10vw 20vh;
    gap: 30px;
  `}
`;

export {
  HeroContainer,
  PitchContainer,
  ProjectButtonContainer,
  ProjectContainer,
};
