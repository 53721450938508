import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import breakpoints from '../../styles/breakpoints';
import { PitchHeadline } from '../../components/headlines/index';
import { Button } from '../../components/buttons/index';

const Container = styled.div < { id: string } >`
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  padding: 0 10vw;
`;

const ContactButton = styled(Button)`
  ${breakpoints.xs`
  margin-bottom: 200px;
  `}
  ${breakpoints.sm`
  margin-bottom: 320px;
  `}
  ${breakpoints.md`
  margin-bottom: 340px;
  `}
  ${breakpoints.lg`
  margin-bottom: 550px;
  `}
  ${breakpoints.xl`
  margin-bottom: 280px;
  `}
  z-index: 1;
`;

const HrHeadline = styled(PitchHeadline)`
  ${breakpoints.xs`
    font-size: 35px;
    margin-top: 65px;
    margin-bottom: 40px;
    width: 330px;
  `}
  ${breakpoints.md`
    font-size: 50px;
    margin-bottom: 60px;
    width: auto;
  `}
  ${breakpoints.lg`
    margin-top: 120px;
    font-size: 65px;
  `}
  ${breakpoints.xl`
    font-size: 75px;
  `}
  height: auto;
  text-align: center;
  z-index: 1;
`;

const HrSlogan = styled.h3`
  ${breakpoints.xs`
    font-size: 15px;
    width: auto;
    margin-bottom: 60px;
    margin-top: 35px;
  `}
  ${breakpoints.sm`
    width: 520px;
  `}
  ${breakpoints.md`
    font-size: 16px;
    width: 606px;
    margin-bottom: 60px;
  `}
  ${breakpoints.lg`
    font-size: 19px;
    width: 750px;
    margin-top: 50px;
  `}
  ${breakpoints.xl`
    width: 800px;
  `}
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  text-align: start;
  letter-spacing: 0.5px;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.black};
  z-index: 1;

  > h3 {
    font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;

    ${breakpoints.xs`
    letter-spacing: 0.5px;
    font-size: 20px;
    margin: 40px 0 0px;
  `}
    ${breakpoints.lg`
    letter-spacing: 0.5px;
    font-size: 40px;
    margin: 50px 0 0px;
  `}
  }

  a {
    padding-bottom: 1px;
    text-decoration: none;
    border-bottom: ${({ theme }) => theme.color.butterscotch.main} 1px solid;
    color: inherit;
  }

  a:visited {
    color: inherit;
  }

  a:hover {
    color: #5c5c5c;
  }
`;

const RocketImage = styled(GatsbyImage)`
  /* stylelint-disable declaration-no-important */
  bottom: 0;
  position: absolute !important;
  width: 100%;
  height: 100vh;
  z-index: 0;

  /* stylelint-enable declaration-no-important */
`;

export {
  Container,
  ContactButton,
  HrHeadline,
  HrSlogan,
  RocketImage,
};
