import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import phoneIcon from '../../images/icon-phone.svg';
import mailIcon from '../../images/icon-mail.svg';
import { ContactPersonContainer, ContactInformationWrapper, ContactDataContainer, ContactInfoImage } from './components';
import { ContactData, ContactPersonName, ContactPersonPosition } from './contact-form-typography';
import 'react-toastify/dist/ReactToastify.css';

type ContactInformationProps = {
  image: IGatsbyImageData,
  name: string,
  jobPosition: string,
  mail: string,
  phone: string,
};
// eslint-disable-next-line padded-blocks
const ContactInformation: React.FC<ContactInformationProps> = ({
  image, name, jobPosition, mail, phone,
}) => {
  return (
    <>
      <ContactPersonContainer>
        <GatsbyImage image={image} alt='contact person' />
        <ContactInformationWrapper>
          <ContactPersonName>{name}</ContactPersonName>
          <ContactPersonPosition>{jobPosition}</ContactPersonPosition>
        </ContactInformationWrapper>
      </ContactPersonContainer>

      <ContactDataContainer>
        <ContactData href={`mailto:${mail}`}>
          <ContactInfoImage src={mailIcon} alt='contact mail' />
          {mail}
        </ContactData>
        <ContactData href={`tel:${phone}`}>
          <ContactInfoImage src={phoneIcon} alt='contact phone' />
          {phone}
        </ContactData>
      </ContactDataContainer>
    </>
  );
};

export default ContactInformation;
