import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  display: grid;
  grid-row-gap: 75px;
  padding: 10vh 15vw 20vh;
  justify-items: center;

  ${breakpoints.xs`
    grid-template-rows: 100px 1fr;
    grid-row-gap: 25px;
  `}
  ${breakpoints.lg`
    grid-template-rows: 200px 1fr;
    grid-row-gap: 75px;
  `}
`;

const ContentContainer = styled.div`
  color: ${({ theme }) => theme.color.basic.black};
  word-break: break-word;

  > h2 {
    font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;

    ${breakpoints.xs`
      letter-spacing: 0.5px;
      font-size: 20px;
      margin: 35px 0 15px;
    `}
    ${breakpoints.lg`
      letter-spacing: 0.5px;
      font-size: 32px;
      margin: 50px 0 20px;
    `}
  }

  > h3 {
    font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;

    ${breakpoints.xs`
      letter-spacing: 0.5px;
      font-size: 18px;
      margin: 35px 0 15px;
    `}
    ${breakpoints.lg`
      letter-spacing: 0.5px;
      font-size: 28px;
      margin: 50px 0 20px;
    `}
  }

  > h4 {
    font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;

    ${breakpoints.xs`
      letter-spacing: 0.5px;
      font-size: 16px;
      margin: 15px 0 10px;
    `}
    ${breakpoints.lg`
      letter-spacing: 0.5px;
      font-size: 24px;
      margin: 25px 0 10px;
    `}
  }

  > p {
    font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    ${breakpoints.xs`
      line-height: 1.56;
      font-size: 14px;
      letter-spacing: 0.56px;

      &:last-of-type {
        margin-top: 15px;
      }
    `}
    ${breakpoints.lg`
      line-height: 1.56;
      font-size: 18px;
      letter-spacing: 0.56px;

      &:last-of-type {
        margin-top: 20px;
      }
    `}
  }

  > ul > li {
    font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    list-style: disc;
    margin-left: 20px;

    ${breakpoints.xs`
      line-height: 1.56;
      font-size: 14px;
      letter-spacing: 0.56px;

      &:last-of-type {
        margin-top: 15px;
      }
    `}
    ${breakpoints.lg`
      line-height: 1.56;
      font-size: 18px;
      letter-spacing: 0.56px;

      &:last-of-type {
        margin-top: 20px;
      }
    `}
  }
`;

export {
  Container,
  ContentContainer,
};
