import React from 'react';
import { InputFieldContainer, InputFieldIcon, Input } from './components';
import 'react-toastify/dist/ReactToastify.css';

type InputFieldProps = {
  icon: any,
  placeholder: string | undefined,
  onChange: (e) => void,
  value: string | undefined,
};
// eslint-disable-next-line padded-blocks
const InputField: React.FC<InputFieldProps> = ({
  icon, placeholder, onChange, value,
}) => {
  return (
    <InputFieldContainer>
      <InputFieldIcon src={icon} alt='icon' />
      <Input
        key={`Input for ${JSON.stringify(placeholder)}`}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </InputFieldContainer>
  );
};

export default InputField;
