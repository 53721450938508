import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';

// import usePlausible from '../../hooks/use-plausible';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { BackgroundButterscotch } from '../components';
import ContactButton from './buttons';
import { ButtonContainer, Container, ContentContainer, Headline, ImageContainer, Subtitle, TextContainer, TitleImage } from './components';

type ButtonInformation = {
  title: string,
  link: string,
};

type QueryData = {
  'allContentfulSection': {
    'nodes': [
      {
        'buttons': ButtonInformation [],
        'subtitle': string,
        'title': string,
        image: [
          fluid: {
            gatsbyImageData: IGatsbyImageData,
          }
        ],
      }
    ],
  },
};

const DevTeamsHeroShot: React.FC = () => {
  // const { plausible } = usePlausible();
  const { allContentfulSection }: QueryData = useStaticQuery(graphql`
    query {
      allContentfulSection(filter: {contentful_id: {eq: "5oj4nmwXh05CvnsNNe9oV0"}}) {
        nodes {
          buttons {
            title
            link
          }
          subtitle
          title
          image {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const {
    nodes:
    [{ title, subtitle, buttons, image }],
  } = allContentfulSection;

  return (
    <>
      <BackgroundButterscotch>
        <Container >
          <ContentContainer>
            <TextContainer>
              <Headline>{title}</Headline>
              <Subtitle>{subtitle}</Subtitle>
              <ButtonContainer>
                {
                  buttons.map((el, index) =>
                    <ContactButton
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      key={el.title}
                      primary={index % 2 !== 1}
                      mobile
                      onClick={async () => navigate(el.link)}
                      width={index === 0 ? '66%' : '34%'}
                    >
                      {el.title}

                    </ContactButton>,
                  )
                }
              </ButtonContainer>
            </TextContainer>
            <ImageContainer>
              <TitleImage image={getImage(image[0].gatsbyImageData)!} alt='image of ' />
            </ImageContainer>
          </ContentContainer>
        </Container>
      </BackgroundButterscotch>
    </>
  );
};

export default DevTeamsHeroShot;
