import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { motion, MotionStyle } from 'framer-motion';

const AnimatedDiv = styled(motion.div)<{ style?: MotionStyle }>`
  height: auto;
  min-height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
`;

export default styled(BackgroundImage)`
  width: 100%;

  /* height: 100vh; */
`;

export {
  AnimatedDiv,
};
