import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const MemberContainer = styled.div`
  > * + * {
    margin-top: 20px;
  }
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: space-between;
  margin: 35px 0;
  height: auto;

  ${breakpoints.xs`
    width: 310px;
    `}
  ${breakpoints.sm`
    width: 350px;
    `}
`;

const Photo = styled(GatsbyImage)`
  width: 190px;
  height: 190px;
  border-radius: 100%;
  -khtml-border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
  mask-size: 100%;
`;

const MaskShadow = styled.div`
  background-size: 100%;
  filter: drop-shadow(1px 1px 4px rgba(166, 50, 69, 0.7));
  -webkit-filter: drop-shadow(1px 1px 4px rgba(166, 50, 69, 0.7));
  -moz-filter: drop-shadow(1px 1px 4px rgba(166, 50, 69, 0.7));
  -ms-filter: drop-shadow(1px 1px 4px rgba(166, 50, 69, 0.7));
  -o-filter: drop-shadow(1px 1px 4px rgba(166, 50, 69, 0.7));
`;

const TeamName = styled.p`
  font-size: 28px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme }) => theme.color.basic.white};
`;

const MemberQuote = styled.p`
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.color.basic.white};
`;

const Tag = styled.div < { key: number } >`
  padding: 6px 8px;
  margin: 2px;
  min-width: 35px;
  border-radius: 14px;
  border: solid 1px ${({ theme }) => theme.color.basic.white};
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: center;
  color: ${({ theme }) => theme.color.basic.white};
`;

const TagContainer = styled.div`
  width: 300px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;

export {
  Photo,
  TeamName,
  MaskShadow,
  MemberContainer,
  Tag,
  TagContainer,
  MemberQuote,
};
