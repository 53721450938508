/* eslint-disable @typescript-eslint/no-extra-parens */
import React from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { useSpring } from 'react-spring';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {
  BackgroundButterscotch, BackgroundDarken,
} from '../../components/components';

import { Button } from '../../components/buttons/index';
import { SmallHeadline } from '../../components/headlines/index';
import { Container, Headline, Saturn, SpaceStaionDiv, Pluto } from './components';

const calc = (x: number, y: number) => [x - (window.innerWidth / 2), y - (window.innerHeight / 2)];
const trans = (x: number, y: number) => `translate3d(${x / 10}px,${y / 10}px,0)`;

type QueryData = {
  saturn: IGatsbyImageData,
  spaceStation: IGatsbyImageData,
  pluto: IGatsbyImageData,
};

const NotFound: React.FC = () => {
  const [props, set] = useSpring(() => (
    { xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }
  ));
  const { saturn, spaceStation, pluto }: QueryData = useStaticQuery(graphql`
    query {
      saturn: file(relativePath: { eq: "saturn-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 100, placeholder: BLURRED)
        }
      }
      spaceStation: file(relativePath: { eq: "space-station.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 100, placeholder: BLURRED)
        }
      }
      pluto: file(relativePath: { eq: "pluto.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <BackgroundButterscotch onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <BackgroundDarken>
        <Container>
          <SpaceStaionDiv
            style={{ transform: props.xy.to(trans) }}
          >
            <GatsbyImage image={getImage(spaceStation)!} alt='spacestation' />
          </SpaceStaionDiv>
          <SmallHeadline light>Lost in Space</SmallHeadline>
          <Headline>404</Headline>
          <Button
            type='button'
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={async () => navigate('/')}
          >
            Bring mich zurück
          </Button>
          <Pluto image={getImage(pluto)!} alt='pluto' />
          <Saturn image={getImage(saturn)!} alt='saturn' />
        </Container>
      </BackgroundDarken>
    </BackgroundButterscotch>
  );
};

export default NotFound;
