import { motion, TargetAndTransition, VariantLabels } from 'framer-motion';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import theme from '../../styles/theme';

type ButtonProps = {
  height?: number | string,
  width?: number | string,
  primary?: boolean,
  outlined?: boolean,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  whileHover?: VariantLabels | TargetAndTransition,
  whileTab?: VariantLabels | TargetAndTransition,
  style?: { opacity: string } | { marginBottom: string },
  fontSize?: string,
};

const Button = styled(motion.button)<ButtonProps>`
  ${breakpoints.xs`
    height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : 40)}px;
    width: ${({ width }: {width?: Pick<ButtonProps, 'width'>}) => (width ? width : 225)}px;
    padding: 0 25px 0 25px;
    font-size: 14px;
  `}
  ${breakpoints.md`
    display: ${({ mobile }) => (mobile ? 'none' : 'block')};
  `}
  ${breakpoints.lg`
    height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : 50)}px;
    width: ${({ width }: {width?: Pick<ButtonProps, 'width'>}) => (width ? width : 260)}px;
    padding: 0 20px 0 20px;
    font-size: 16px;
  `}
  background: ${({ primary }) => (primary ? `linear-gradient(259deg, ${theme.color.butterscotch.tint}, ${theme.color.butterscotch.main}, ${theme.color.butterscotch.shade})` : `transparent`)};
  background-size: 200%;
  background-position: 100% 0;
  border: ${({ primary }) => (primary ? 'none' : `${theme.color.hamam.main} solid 0px `)};;
  border-radius: 26px;
  box-shadow: ${({ primary }) => (primary ? `0 5px 10px 3px rgba(166, 50, 69, 0.1)` : `none`)};
  text-transform: uppercase;
  font-family: ${theme.typography.akkurat}, sans-serif;
  color: ${({ primary }) => (primary ? theme.color.basic.white : theme.color.hamam.main)};
  color: ${({ outlined }) => outlined && theme.color.hamam.main};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  text-align: center;
  cursor: pointer;
  transition:
    ${({ primary }) => (primary ? 'background .5s ease-in-out' : 'border .1s ease-in-out')},
    box-shadow .1s ease-in-out
  ;

  &:focus, &:hover {
    background-position: 0 0;
    border: ${({ primary }) => (primary ? 'none' : `${theme.color.hamam.main} solid 4px `)};
    box-shadow: ${({ primary }) => (primary ? `0 5px 10px 3px rgba(166, 50, 69, 0.1)` : `0 5px 10px 3px rgba(166, 50, 69, 0.1)`)};
  }
`;

const ButtonSolid = styled(motion.button)<ButtonProps>`
  ${breakpoints.xs`
    min-height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : 40)}px;
    min-width: ${({ width }: {width?: Pick<ButtonProps, 'width'>}) => (width ? width : 225)}px;
    padding: 0 25px 0 25px;
    font-size: ${({ fontSize }: {fontSize?: Pick<ButtonProps, 'fontSize'>}) => (fontSize ? fontSize : '11px')};
  `}
  ${breakpoints.lg`
    min-height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : 50)}px;
    min-width: ${({ width }: {width?: Pick<ButtonProps, 'width'>}) => (width ? width : 250)}px;
    padding: 0 15px 0 15px;
  `}
  ${breakpoints.xl`
    min-width: ${({ width }: {width?: Pick<ButtonProps, 'width'>}) => (width ? width : 285)}px;
    padding: 0 20px 0 20px;
    font-size: 12px;
  `}
  background: white;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-items: center;
  place-content: center;
  width: 100%;
  align-items: center;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 15px -5px ${({ primary }) => (primary ? `${theme.color.hamam.shade}` : `${theme.color.hamam.shade}`)};
  text-transform: uppercase;
  font-family: ${() => theme.typography.akkurat}, sans-serif;
  color: ${({ primary }) => (primary ? `${theme.color.butterscotch.shade}` : `${theme.color.hamam.shade}`)};
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  text-align: center;
  cursor: pointer;

  :focus {
    outline: none;
    border: 2.5px solid ${({ primary }) => (primary ? theme.color.butterscotch.main : theme.color.hamam.main)};
  }
`;

export {
  Button,
  ButtonSolid,
};
