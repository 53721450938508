import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import breakpoints from '../../styles/breakpoints';

const ValueContainer = styled.div`
  ${breakpoints.xs`
    width: 310px;
    height: auto;
    `}
  ${breakpoints.lg`
    width: 400px;
    height: auto;
    `}
  display: flex;
  flex-direction: column;
  place-items: center;
  height: 380px;
`;

const Icon = styled(GatsbyImage) < { image: IGatsbyImageData } >`
  ${breakpoints.xs`
    width: 130px;
    height: auto;
  `}
  ${breakpoints.md`
    width: 130px;
  `}
  ${breakpoints.lg`
    width: 150px;
  `}
  ${breakpoints.xl`
    width: 180px;
  `}
`;

const ValueName = styled.p`
  ${breakpoints.xs`
    font-size: 26px;
  `}
  ${breakpoints.md`
    font-size: 28px;
  `}
  ${breakpoints.lg`
    font-size: 30px;
  `}
  ${breakpoints.xl`
  font-size: 32px;

  `}
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme }) => theme.color.basic.white};
`;

const ValueText = styled.p`
  ${breakpoints.xs`
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 100px;
  `}
  ${breakpoints.lg`
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 145px;
  `}
  ${breakpoints.xl`
  `}
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.color.basic.white};
  width: 85%;
`;

export {
  Icon,
  ValueName,
  ValueContainer,
  ValueText,
};
