import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
  BackgroundSilver,
  BackgroundDarken,
} from '../../components/components';
import {
  SmallHeadline,
  SectionHeadline,
} from '../../components/headlines/index';
import {
  Container,
  GridContainer,
  ContentBox,
  Icon,
  HeadlineContainer,
} from './component';

type QueryData = {
  allContentfulSection: {
    nodes: {
      title: string,
      subtitle: string,
      content: {
        name: string,
        image: {
          gatsbyImageData: IGatsbyImageData,
        },
      }[],
    }[],
    title: string,
  },
};

const DevTarget: React.FC = () => {
  const { allContentfulSection }: QueryData = useStaticQuery(
    graphql`
      query {
        allContentfulSection(filter: {title: {eq: "Geräte für die wir Entwickeln"}}) {
          nodes {
            title
            subtitle
            content {
              ... on ContentfulTargetDevices {
                name
                image {
                  gatsbyImageData (
                    width: 750
                    height: 750
                    formats: WEBP
                    quality: 50
                    placeholder: TRACED_SVG
                  )
                }
              }
            }
          }
        }
      }
    `,
  );

  const { nodes: [{ subtitle, content }] } = allContentfulSection;

  return (
    <BackgroundSilver>
      <BackgroundDarken>
        <Container>
          <HeadlineContainer>
            <SmallHeadline>{subtitle}</SmallHeadline>
          </HeadlineContainer>
          <GridContainer>
            {content.map((data) => (
              <ContentBox key={data.name} >
                <Icon image={data.image.gatsbyImageData} alt={`image for ${data.name}`} />
                <SectionHeadline dark>
                  {data.name}
                </SectionHeadline>
              </ContentBox>
            ))}
          </GridContainer>
        </Container>
      </BackgroundDarken>
    </BackgroundSilver>
  );
};

export default DevTarget;
